import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import Color from 'color'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { registerForEvent, clearEventRegistration } from '../../redux/event/actions'
import { register } from '../../redux/registration/actions'
import LoginButton from '../auth/LoginButton'

class RegistrationForm extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      data: {},
      validated: false,
      disabled: false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRegistration = this.handleRegistration.bind(this)
    this.getButtonStyle = this.getButtonStyle.bind(this)
    this.getForm = this.getForm.bind(this)
    this.getSuccessMessage = this.getSuccessMessage.bind(this)
    this.reset = this.reset.bind(this)

  }

  handleInputChange(event) {
    const target = event.target
    this.setState({
      data: {...this.state.data, ...{[target.name]: target.value}}
    })
  }

  handleSubmit(event){
    this.setState({
      serverError: ''
    })
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    this.setState({
      disabled: true
    })
    const {dispatch } = this.props
    if(!this.props.authenticated){
      dispatch(register(
        $('input[name="firstName"]', $(form)).val(),
        $('input[name="surname"]', $(form)).val(),
        $('input[name="email"]', $(form)).val(),
        $('input[name="password"]', $(form)).val()
      )).then(result => {
        if(!this.props.authenticated){
          throw new Error('Platform registration failed')
        }
        return this.handleRegistration(form)
      })
      .catch(error => {
        this.setState({
          disabled: false
        })
        console.log(error.message)
        //dispatch(eventRegistrationError(this.props.id, error))
      })
    }else{
      this.handleRegistration(form)
    }
  }

  handleRegistration(form){

    let data = {}

    this.props.event.registration.fields.map((fieldInfo, index) => {
      switch(fieldInfo.type){
        case 'radio':
          data[fieldInfo.name] = $('*[name="' + fieldInfo.name + '"]:checked', $(form)).val()
          break
        case 'heading':
          break
        default:
          data[fieldInfo.name] = $('*[name="' + fieldInfo.name + '"]', $(form)).val()
      }
      return fieldInfo
    })

    const { dispatch } = this.props

    return dispatch(registerForEvent(this.props.id, data))
  }

  reset(){
    this.setState({
      validated: false,
      disabled: false
    })
    const { dispatch } = this.props
    dispatch(clearEventRegistration())
  }

  render(){
    if(this.props.status === 'success'){
      return this.getSuccessMessage()
    }
    return this.getForm()
  }

  getButtonStyle(){
    var primaryColor = ((this.props.entity || {}).colors || {}).primary || null
    if(primaryColor){
      var color = Color(primaryColor)
      return {
        background: primaryColor + ' linear-gradient(180deg,' + color.lighten(0.2).hex().toString() + ',' + color.darken(0.2).hex().toString() + ') repeat-x',
        borderColor: primaryColor
      }
    }
  }

  getSuccessMessage(){
    var message = (((this.props.event || {}).registration || {}).message || {}).success || 'Thank you for registering. Please check your inbox for further details'
    var buttonStyle = this.getButtonStyle()
    return (
      <React.Fragment>
        <h3>Registration complete</h3>
        <p>{ message }</p>
         <Button variant="primary" type="button" onClick={this.reset} style={buttonStyle} className="mb-3" block>
          Done
        </Button>
      </React.Fragment>
      )
  }

  getForm(){

    const passwordRegex = '.*' + window.passwordPattern.replace(/^\/\^?/, '').replace(/\$?\/$/, '') + '.*'

    const errorStyle = {
      display: (this.props.error) ? 'block' : 'none'
    }

    const buttonStyle = this.getButtonStyle()

    const title = this.props.event && this.props.event.registration && this.props.event.registration.title ? this.props.event.registration.title : 'Register'

    let controlId

    const userFields = !this.props.authenticated ?
      <React.Fragment>
        <Form.Group controlId="RegistrationFirstName">
          <Form.Label className="mt-2 mb-0">First name</Form.Label>
          <Form.Control type="text" name="firstName" placeholder="Enter your first name" onChange={this.handleInputChange} required />
          <Form.Control.Feedback type="invalid">
            Please supply your first name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="RegistrationSurname">
          <Form.Label className="mt-2 mb-0">Last name</Form.Label>
          <Form.Control type="text" name="surname" placeholder="Enter your last name" onChange={this.handleInputChange} required />
          <Form.Control.Feedback type="invalid">
            Please supply your last name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="RegistrationEmail">
          <Form.Label className="mt-2 mb-0">Email address</Form.Label>
          <Form.Control type="email" name="email" placeholder="Enter your email" value={this.state.email} onChange={this.handleInputChange} autocorrect="off" autocapitalize="none" required />
          <Form.Control.Feedback type="invalid">
            Please supply a valid email address
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="RegistrationPassword">
          <Form.Label className="mt-2 mb-0">Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Please choose your password. This will enable you to access the event."
            title={window.passwordInstructions}
            onChange={this.handleInputChange}
            required
            pattern={passwordRegex} />
          <Form.Control.Feedback type="invalid">
            {window.passwordInstructions}
          </Form.Control.Feedback>
        </Form.Group>
      </React.Fragment>
      : null

      return (<Form id="EventRegistrationForm" novalidate="true" className="pb-4" disabled={this.state.disabled} validated={this.state.validated} onSubmit={this.handleSubmit}>

        <h2>{title}</h2>

        {userFields}

        {this.props.event.registration.fields.map((fieldInfo, index) => {

          controlId = 'Registration' + fieldInfo.name

          switch(fieldInfo.type){

            case 'heading':
              return <h3 className="mt-2">{fieldInfo.label}</h3>

            case 'instruction':
              return <p>{fieldInfo.label}</p>

            case 'radio':
              return <Form.Group controlId={controlId}>
                <Form.Label className="mt-2 mb-0 me-2" className="me-2">{fieldInfo.label}</Form.Label>
                {fieldInfo.options.map((option, index) => {
                  return <Form.Check
                      inline
                      required={(fieldInfo.validation.type || false) === 'required'}
                      type="radio"
                      name={fieldInfo.name}
                      value={option.value}
                      label={option.label}
                    />
                })}
                <Form.Control.Feedback type="invalid">
                  {fieldInfo.validation ? fieldInfo.validation.message : ''}
                </Form.Control.Feedback>
              </Form.Group>

            case 'checkbox':
              return <Form.Group controlId={controlId}>
                <Form.Check
                  id={controlId}
                  name={fieldInfo.name}
                  value={fieldInfo.value}
                  required={(fieldInfo.validation && fieldInfo.validation.type === 'required')}
                  label={fieldInfo.label}
                  feedback={fieldInfo.validation ? fieldInfo.validation.message : fieldInfo.name + ' is required'}
                />
              </Form.Group>

            case 'select':
              return <Form.Group controlId={controlId}>
                <Form.Label className="mt-2 mb-0">{fieldInfo.label}</Form.Label>
                <Form.Control as="select" custom name={fieldInfo.name}>
                  {fieldInfo.options.map((option, index) => {
                    return <option value={option.value}>{option.label}</option>
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {fieldInfo.validation ? fieldInfo.validation.message : ''}
                </Form.Control.Feedback>
              </Form.Group>

            case 'date':
              return <Form.Group controlId={controlId}>
                <Form.Label className="mt-2 mb-0">{fieldInfo.label}</Form.Label>
                  <div className="input-group date" id={controlId} data-target-input="nearest">
                    <input
                      name={fieldInfo.name}
                      onChange={this.handleInputChange}
                      type="text"
                      placeholder={fieldInfo.placeholder || ''}
                      className="form-control datetimepicker-input"
                      data-target={'#'+ controlId}
                      required={(fieldInfo.validation.type || false) === 'required'}
                      />
                    <div className="input-group-append" data-target={'#'+ controlId} data-toggle="datetimepicker">
                        <div className="input-group-text"><FontAwesomeIcon icon={faCalendar} /></div>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {fieldInfo.validation ? fieldInfo.validation.message : ''}
                    </Form.Control.Feedback>
                  </div>
              </Form.Group>

            default:
              return <Form.Group controlId={controlId}>
                <Form.Label className="mt-2 mb-0">{fieldInfo.label}</Form.Label>
                <Form.Control
                  type="text"
                  name={fieldInfo.name}
                  placeholder={fieldInfo.placeholder || ''}
                  onChange={this.handleInputChange}
                  required={(fieldInfo.validation.type || false) === 'required'}
                  />
                <Form.Control.Feedback type="invalid">
                  {fieldInfo.validation ? fieldInfo.validation.message : ''}
                </Form.Control.Feedback>
              </Form.Group>

          }
        })}

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid" style={errorStyle}>
            <Alert variant="danger">
              {this.props.error}
            </Alert>
          </Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit" style={buttonStyle} block>
          Register
        </Button>
      </Form>

      )
  }

}

RegistrationForm.propTypes = {
  error: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { errors, user, events: { [ownProps.id]: event}, entities, event: { registration } } = state

  const entity = event && event.entity && entities && entities[event.entity.id] ? entities[event.entity.id] : null

  let error = ''
  if(errors && errors.registration){
    switch (errors.registration.message){
      case 'User exists':
        error = <React.Fragment>It looks like you're already registered with us. Please <LoginButton inline={true} label="click here to sign in or reset your password" />.</React.Fragment>
        break
      default:
        error = errors.registration.message
    }
  }
  if(!error && errors && errors.event && errors.event.registration){
    switch (errors.event.registration.message){
      case 'Unauthorized':
        error = <React.Fragment>It looks like your session has timed out. Please <LoginButton inline={true} label="click here to sign in again" />.</React.Fragment>
        break
      default:
        error = errors.event.registration.message
    }
  }

  return {
    error,
    authenticated: user && user.status && user.status === 'fetched',
    event,
    entity,
    status: registration && registration.status && registration.status ? registration.status : ''
  }
}

export default connect(mapStateToProps)(RegistrationForm)