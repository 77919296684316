import React from 'react';

class TimeString extends React.Component {

  render() {

    const time = new Date(this.props.time)

    if(isNaN(time.getTime()) || !time.getTime()){
      return null
    }

    this.timeString = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric', minute: 'numeric',
      hour12: false,
      timeZone: 'Europe/London'
    }).format(time).replace(/\b0:/, '12:')

    this.dateString = new Intl.DateTimeFormat('en-GB', {
      month: 'short', day: 'numeric',
      timeZone: 'Europe/London'
    }).format(time)

    this.yearString = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      timeZone: 'Europe/London'
    }).format(time)

    const emphasis = this.props.emphasis || 'time'

    return <React.Fragment>
    {
       ['time', 'date', 'year'].map(item => {
         let cssClass = 'time-display time-display--' + item
         cssClass += item === emphasis ? ' time-display--emphasised' : ''
         return (<React.Fragment key={item} ><span className={cssClass}>{ this[item + 'String'] }</span> </React.Fragment>)
       })
    }
    </React.Fragment>
  }

}

export default TimeString;