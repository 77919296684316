import React from 'react';
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'

class ImageSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: uuidv4(),
      image: this.props.image,
      selectedFile: null,
      serverError: '',
      disabled: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getInput = this.getInput.bind(this);
    this.getThumbnail = this.getThumbnail.bind(this);
    this.getDeleteButton = this.getDeleteButton.bind(this);
    this.reset = this.reset.bind(this);
  }

  render() {

    return (
      <React.Fragment>
        {this.getThumbnail()}
        {this.getInput()}
      </React.Fragment>
    )

  }

  getDeleteButton(){
      return <Button className="ms-2 position-absolute thumbnail__delete" variant="light" size="sm" onClick={this.handleDelete} title="Remove">
        <span aria-hidden="true" className="font-weight-bold">&times;</span>
      </Button>
  }

  getThumbnail(){
    return this.state.image ?
        this.props.type === 'logo' ?
          <div className="thumbnail bg-secondary d-flex align-items-center justify-content-center img-thumbnail p-3 position-relative">
            <img className="mw-100 mh-100" src={this.state.image} alt="Logo" />
            {this.getDeleteButton()}
          </div>
          :
          <div className="thumbnail background-image-cover img-thumbnail position-relative" style={{ backgroundImage: 'url(' + this.state.image + ')'}}>
            {this.getDeleteButton()}
          </div>
      : null
  }

  getInput(){
    if(this.state.image){
      return null
    }

    let cssClass  = 'file-input'
    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    if(this.props.mode === 'icon'){
      cssClass += ' file-input--icon'
      return (<label htmlFor={this.state.id} className={cssClass} title="Upload an image">
        <FontAwesomeIcon icon={faCamera} />
         <input id={this.state.id} type="file" disabled={this.state.disabled} name={this.props.name} onChange={this.handleChange} accept="image/*"/>
       </label>)
    }

    return (
      <div className={cssClass}>
        <input id={this.state.id} type="file" disabled={this.state.disabled} name={this.props.name} onChange={this.handleChange} accept="image/*"/>
      </div>
      )
  }

  handleChange(event){
    this.setState({
      selectedFile: event.target.files[0],
      disabled: true,
    })
    const data = new FormData()
    data.append('file', event.target.files[0])

    var requestOptions = {
      method: 'POST',
      body: data,
      credentials: 'include'
    }
    var auth = localStorage.getItem('mediaApiToken')
    if(auth) requestOptions.headers = { 'Authentication-Info' :  auth }

    let url = window.endpoint.media + '/media'
    const query = [];
    const width = this.props.width || 2560
    const height = this.props.height || 1920
    query.push('width=' + width)
    query.push('height=' + height)
    url += '?' + query.join('&')

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.errors){
            throw new Error(data.errors[0].detail || data.errors[0].title)
        }
        this.setState({
          image: data.data.attributes.url,
          disabled: false
        })
        if(this.props.handleChange){
          this.props.handleChange(this.props.name, data.data.attributes.url)
        }
      })
      .catch(err => {
        this.setState({
          disabled: false,
          serverError: err.message
        })
        //this.handleSaveError(err)
        console.log(err.message)
      })

  }

  handleDelete(){
    this.reset()
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, null)
    }
  }

  reset(){
    this.setState({
      image: null,
      disabled: false,
      selectedFile: null
    })
    $('input[type="file"][name="' + this.props.name + '"]').val('')
  }
}



export default ImageSelector