import React from 'react';
import Button from 'react-bootstrap/Button';
import EditModal from './EditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { connect } from 'react-redux'

class EditButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.authorised){
      return null
    }

    const variant = this.props.variant || 'primary';
    const label = this.props.id === 'new' ? 'Add streamed content' : 'Edit'
    const icon = this.props.id === 'new' ? faPlusSquare : faEdit

    const modal = <EditModal
      id={this.props.id}
      event={this.props.event}
      />

    if(this.props.mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={this.props.className}>
            <FontAwesomeIcon icon={icon} />
          </button>
          {modal}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
          <FontAwesomeIcon icon={icon} className="d-md-none" /><span className="d-none d-md-inline text-nowrap">{label}</span>
        </Button>
        {modal}
      </React.Fragment>
    )
  }



  handleClick(e){
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(showModal('streamevents',this.props.id, true))
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }


}

const mapStateToProps = (state, ownProps) => {
  const { user, streamevents: {[ownProps.id]: streamevent}, events } = state

  // Check for owner
  let authorised = (user && user.id && streamevent && streamevent.owner && streamevent.owner.id === user.id)

  // Check if event admin
  if(!authorised && streamevent && streamevent.event && streamevent.event.id){
    authorised = (user && user.administrator && user.administrator.events && Array.isArray(user.administrator.events) && user.administrator.events.includes(streamevent.event.id))
    if(!authorised && events[streamevent.event.id] && events[streamevent.event.id].entity && events[streamevent.event.id].entity.id){
      authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(events[streamevent.event.id].entity.id))
    }
  }

  // Check if entity admin
  if(!authorised && streamevent && streamevent.entity && streamevent.entity.id){
    authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(streamevent.entity.id))
  }

  // Check if event admin
  if(!authorised && ownProps.event){
    authorised = (user && user.administrator && user.administrator.events && Array.isArray(user.administrator.events) && user.administrator.events.includes(ownProps.event))
    if(!authorised && events[ownProps.event] && events[ownProps.event].entity && events[ownProps.event].entity.id){
      authorised = (user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities) && user.administrator.entities.includes(events[ownProps.event].entity.id))
    }
  }

  return {
    authorised
  }
}

export default connect(mapStateToProps)(EditButton)