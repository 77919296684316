import React from 'react'
import { Link } from 'react-router-dom'
import ReactCookieConsent from 'react-cookie-consent'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getTenantEntity } from '../../redux/state'

class CookieConsent extends React.Component{

  render() {

    const { tenant } = this.props

    const name = tenant ? tenant.name : 'Zarucchi'

    return <ReactCookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="ZarucchiCookieAcceptance"
      containerClasses="bg-dark"
      enableDeclineButton={true}
      declineButtonClasses="btn btn-outline-light my-3 ms-3"
      declineButtonText="Decline"
      buttonClasses="btn btn-light my-3 mx-3"
      disableButtonStyles={true}
      expires={180}
    >
      <Link to="/privacy-policy" className="text-white">The { name } platform uses cookies to enhance the user experience.</Link>
    </ReactCookieConsent>
  }

}

CookieConsent.propTypes = {
  tenant: PropTypes.object
}

const mapStateToProps = (state) => {

  return {
    tenant: getTenantEntity(state)
  }


}

export default connect(mapStateToProps)(CookieConsent)