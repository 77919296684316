import React from 'react';
import Button from 'react-bootstrap/Button'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faLink } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'

const SortableItem = SortableElement(({data, handleDelete, handleCaptionChange, handleLinkChange, mode}) => {
  const popover = (
    <Popover>
      <Popover.Header as="h3">Link</Popover.Header>
      <Popover.Body>
        <input
          type="url"
          className=""
          placeholder="Add link"
          onChange={handleLinkChange}
          value={data.link}
          />
      </Popover.Body>
    </Popover>
  )
  const background = mode === 'logo' ? 'background-image-contain' : 'background-image-cover'
  return <li className="p-0 sortableItem">
    <div className={`thumbnail ${background} img-thumbnail position-relative me-3 mb-3`} style={{ backgroundImage: 'url(' + data.url + ')'}}>
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <Button className="me-2 position-absolute thumbnail__link" variant="light" size="sm"><FontAwesomeIcon icon={faLink} className={ data.link ? 'text-primary' : ''} /></Button>
      </OverlayTrigger>
      <Button className="ms-2 position-absolute thumbnail__delete" variant="light" size="sm" onClick={handleDelete} title="Remove">
        <span aria-hidden="true" className="font-weight-bold">&times;</span>
      </Button>
      <input
        type="text"
        className="position-absolute p-2 black-grad-down w-100 text-white border-0 p-0 m-0 thumbnail__caption"
        placeholder="Add caption"
        onChange={handleCaptionChange}
        value={data.caption}
        />
    </div>
    </li>
  }
)

const SortableList = SortableContainer(({items, handleDelete, handleTextChange, mode}) => {
  return (
    <ul className="list-unstyled sortableContainer d-flex flex-wrap mb-0">
      {items.map((data, index) => (
        <SortableItem
          key={`item-${data.url}-${index}`}
          data={data}
          mode={mode}
          handleDelete={() => { handleDelete(index) }}
          handleCaptionChange={(e) => { handleTextChange(index, 'caption', $(e.target).val()) }}
          handleLinkChange={(e) => { handleTextChange(index, 'link', $(e.target).val()) }}
          index={index} />
      ))}
    </ul>
  )
})

const arrayMove = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

class ImagesSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.images || [],
      serverError: ''
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.getInput = this.getInput.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  render() {

    const { data } = this.state
    const { mode='image' } = this.props

    return (
      <React.Fragment>
      <SortableList
        items={data}
        onSortEnd={this.onSortEnd}
        handleDelete={this.handleDelete}
        handleTextChange={this.handleTextChange}
        helperClass="sortableHelper"
        axis="xy"
        mode={mode}
        helperContainer={this.props.container ? this.props.container.current : null}
        distance={1}
       />
      {this.getInput()}
      </React.Fragment>
    )

  }

  getInput(){

    let cssClass  = 'file-input'
    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    if(this.props.mode === 'icon'){
      cssClass += ' file-input--icon'
      return (<label htmlFor={this.state.id} className={cssClass} title="Upload an image">
        <FontAwesomeIcon icon={faCamera} />
         <input id={this.state.id} type="file" name={this.props.name} onChange={this.handleUpload} accept="image/png,image/gif,image/jpeg,image/svg"/>
       </label>)
    }

    return (
      <div className={cssClass}>
        <input id={this.state.id} type="file" name={this.props.name} onChange={this.handleUpload} accept="image/png,image/gif,image/jpeg,image/svg"/>
      </div>
      )
  }

  handleUpload(event){
    this.setState({
      selectedFile: event.target.files[0],
    })
    const data = new FormData()
    data.append('file', event.target.files[0])

    var requestOptions = {
      method: 'POST',
      body: data,
      credentials: 'include'
    }
    var auth = localStorage.getItem('mediaApiToken')
    if(auth) requestOptions.headers = { 'Authentication-Info' :  auth }

    let url = window.endpoint.media + '/media'
    const query = [];
    const width = this.props.width || 2560
    const height = this.props.height || 1920
    query.push('width=' + width)
    query.push('height=' + height)
    url += '?' + query.join('&')

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.errors){
            throw new Error(result.errors[0].detail || result.errors[0].title)
        }
        const { data } = this.state
        data.push({ url: result.data.attributes.url })
        this.setState({
          data
        })
        if(this.props.handleChange){
          this.props.handleChange(this.props.name, data)
        }
        $('input[type="file"][name="' + this.props.name + '"]').val('')
      })
      .catch(err => {
        this.setState({
          serverError: err.message
        })
        $('input[type="file"][name="' + this.props.name + '"]').val('')
        //this.handleSaveError(err)
        console.log(err.message)
      })

  }

  handleDelete(index){
    const { data } = this.state
    data.splice(index, 1)
    this.setState({
      data
    })
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, data)
    }
  }

  handleTextChange(index, prop, caption){
    const { data } = this.state
    data[index][prop] = caption
    this.setState({
      data
    })
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, data)
    }
  }

  onSortEnd({oldIndex, newIndex}){
    const { data } = this.state
    arrayMove(data, oldIndex, newIndex)
    this.setState({
      data
    })
    if(this.props.handleChange){
      this.props.handleChange(this.props.name, data)
    }
  }
}



export default ImagesSelector