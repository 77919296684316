import React from 'react';
import $ from 'jquery';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardGroup from 'react-bootstrap/CardGroup';
import Card from './Card';
import ListItem from './ListItem';
import { connect } from 'react-redux'
import { fetchFeed } from '../../redux/feed/actions'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { setFeedDisplay } from '../../redux/ui/actions'
import PropTypes from 'prop-types'

class Feed extends React.Component {

  constructor(props){
    super(props)
    this.loadFeed = this.loadFeed.bind(this)
  }

  componentDidMount() {
    this.loadFeed()
  }

  loadFeed(){
    const { dispatch, name, filter, sort, id } = this.props
    dispatch(fetchFeed(
      name,
      'products',
      filter,
      sort,
      { path: '/entities/' + id + '/products', clear: true}
    ))
  }

  getItem(item){
    switch(this.props.view){
      case 'list':
        return <ListItem id={item.id} feed={this.props.name} />
      default:
        return <Card id={item.id} feed={this.props.name} />
    }
  }

  render() {

    if(!this.props.data || !this.props.data.length){
      return null
    }

    const { dispatch } = this.props

    if(this.timeout){
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      if(this.props.scrollPosition && $('#' + this.props.scrollPosition).length){
        $('html, body').animate({
            scrollTop: ($('#' + this.props.scrollPosition).offset().top - $('.feed-header__controls').height() - 15) + 'px'
        }, {
          duration: 100,
          complete: () => {
            if($('#' + this.props.scrollPosition).length){
              dispatch(setFeedScrollPosition(this.props.name, null))
            }
          }
        })
      }
    }, 250)

    return (
      <CardGroup>
        {this.props.data.map(item => this.getItem(item))}
      </CardGroup>
    )
  }

}

Feed.propTypes = {
  data: PropTypes.array.isRequired,
  filter: PropTypes.object,
  sort: PropTypes.object,
  scrollPosition: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const { feeds, ui } = state

  if(!feeds[ownProps.name] || feeds[ownProps.name].status !== 'fetched'){
    return {
      data: []
    }
  }

  const { items, filter=ownProps.filter, sort=ownProps.sort, scrollPosition, resource } = feeds[ownProps.name]

  const now = new Date()
  const data = (items || []).map(item => {
    return state.products[item.id]
  })

  return {
    data,
    filter,
    sort,
    scrollPosition,
    view: ui && ui.feeds && ui.feeds[ownProps.name] && ui.feeds[ownProps.name].display ? ui.feeds[ownProps.name].display : 'grid'
  }
}

export default connect(mapStateToProps)(Feed)