import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEntityCart } from '../../redux/shopping/state'
import { setCartOfferCodeAndSave } from '../../redux/shopping/actions'

export default props => {

  const { id, className, entity } = props

  const dispatch = useDispatch()

  const code = useSelector(state => {
    const cart = getEntityCart(state, entity)
    if(cart && cart.offer && cart.offer.code){
      return cart.offer.code
    }
    return code
  })

  const [ value, setValue ] = useState(code)

  if(!entity){
    return null
  }

  const apply = () => {
    dispatch(setCartOfferCodeAndSave(entity, value))
  }

  return <Form.Group controlId={id} className={className}>
  <div className="d-flex">
    <Form.Control onChange={e => setValue(e.target.value)} placeholder="Offer code" value={value} className="me-2" />
    <Button onClick={apply} variant="secondary" className="text-nowrap" size="sm">
      Apply
    </Button>
  </div>
  <Form.Text className="text-muted ms-2">
    If you have a discount code, enter it here and click 'Apply'.
  </Form.Text>
</Form.Group>
}