
import {
  SET_FEED_STATUS,
  SET_FEED_FILTER,
  SET_FEED_ORDER,
  SET_FEED_SCROLL_POSITION,
  CLEAR_FEED,
  POPULATE_FEED
} from './actions'

import {
  FETCH_TOKEN_ERROR
} from '../token/actions'

const defaultState = {
  status: 'initialised',
  filter: {},
  sort: {},
  items: [],
  error: null,
  scrollPosition: null,
  resource: null
}

const feed = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case POPULATE_FEED:
      let scrollPosition = null
      let items = [...(state.items || [])]
      let incoming = action.data.map(x => ({ id: x.id, resource: action.resource }))
      // remove unwanted
      items = action.opts && action.opts.clear ? items.filter(item => incoming.findIndex(e => e.resource === item.resource && e.id === item.id) === -1) : items
      // add new
      items = items.concat(incoming.filter(item => items.findIndex(e => e.resource === item.resource && e.id === item.id) === -1))
      let newState = {...state, ...{
        items: items,
        error: null,
        resource: (state.resource || []).concat([action.resource]).filter((val, index, arr) => { return arr.indexOf(val) === index }),
        filter: action.filter,
        sort: action.sort || state.sort,
        opts: action.opts || state.opts,
        scrollPosition: state.scrollPosition || scrollPosition
      }}
      if(newState.sort === 'random'){
        // Randomise array - https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
        newState.items = ((a) => {
          for (let i = a.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [a[i], a[j]] = [a[j], a[i]];
          }
          return a;
        })(newState.items)
      }
      return newState
    case CLEAR_FEED:
      return {...state, ...{ items: [], status: 'initialised', error: null, opts: {}, scrollPosition: null }}
    case SET_FEED_SCROLL_POSITION:
      return {...state, ...{ scrollPosition: action.id }}
    case SET_FEED_STATUS:
      return {...state, ...{ status: action.status, error: action.error }}
    case SET_FEED_FILTER:
      return {...state, ...{ filter: action.filter }}
    case SET_FEED_ORDER:
      return {...state, ...{ sort: Array.isArray(action.sort) ? action.sort : [action.sort] }}
    default:
      return state
  }
}

export const feeds = (state = {}, action) => {
  switch (action.type) {
    case POPULATE_FEED:
    case FETCH_TOKEN_ERROR:
    case CLEAR_FEED:
    case SET_FEED_SCROLL_POSITION:
    case SET_FEED_FILTER:
    case SET_FEED_ORDER:
    case SET_FEED_STATUS:
      return {...state, ...{ [action.name]: { ...state[action.name], ...feed(state[action.name] || defaultState, action) }}}
    default:
      return state
  }
}