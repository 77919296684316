import React from 'react'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import EditButton from './EditButton'
import TextBlock from '../display/TextBlock'
import TimeString from '../display/TimeString'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { getEventOfferings } from '../../redux/state'
import GuestButton from '../guestlist/Button'
import Venue from './Venue'

class Card extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
  }

  render() {

    const start = new Date(this.props.data.datetime)
    const startElement = start.getTime() ? <p className="feed-card__start"><TimeString time={start} emphasis={this.getTimeEmphasis()} /></p> : null

    const strapline = this.props.data.strapline || (this.props.data.description ? this.props.data.description.split('\n')[0] : null)

    const privateFlag = this.props.data.visibility === 'private' ?
      <Button
        variant="danger"
        size="sm"
        className="mt-2 me-auto feed-card__flag feed-card__flag--private"
        >Private</Button>
      : null

    const tags = this.props.data.tags ?
      <p className="font-italic">
         {this.props.data.tags.join(', ')}
      </p>
      : null

    let cssClass = 'feed-card feed-card--event', expandIcon = faPlusCircle
    if(this.state.expanded){
      cssClass += ' feed-card--expanded'
      expandIcon = faMinusCircle
    }

    let editButtons = []

    editButtons.push(<GuestButton
      key="guest-button"
      mode="icon"
      resource={{type: 'events', id: this.props.id}}
      url={window.location.origin + '/events/' + (this.props.data.alias || this.props.data.id) + '/feed'}
      className="feed-card__edit feed-card__btn--icon d-block py-2 ps-2 ms-2"
    />)

    editButtons.push(<EditButton
      key="edit-button"
      mode="icon"
      id={this.props.id}
      feed={this.props.feed}
      className="feed-card__edit feed-card__btn--icon d-block py-2 ps-2 ms-2"
    />)

    if(editButtons.length){
      editButtons = <p className="banner__start d-flex justify-content-end text-end">{ editButtons }</p>
    }

    return (

      <div id={this.props.id} key={this.props.key} className={cssClass} style={{ backgroundImage: 'url(' + this.props.data.image + ')' }}>
        <div className="feed-card__inner">
          <div className="feed-card__top">
            <div className="feed-card__controls d-flex justify-content-end align-items-start px-3 py-2 w-100">
               {privateFlag}
               {startElement}
               {editButtons}
            </div>
            <div className="feed-card__title px-3 pt-2 w-100">
              <button onClick={this.handleClick} title="View event details" className="btn btn-light btn-sm"><FontAwesomeIcon icon={faInfoCircle} className="me-2" /> { this.props.data.access === 'ticket' ? 'Details & booking' : 'View' }</button>
              <h4 className="text-white m-0 pt-3">{this.props.data.name}</h4>
              {this.getPerformerElement()}
              <Venue id={this.props.id} className="mb-1 fw-bold"/>
            </div>
          </div>
          <div className="feed-card__bottom">
            <div className="feed-card__description px-3 pt-2 pb-3 w-100">
              <TextBlock
                text={strapline}
                className="m-0 pb-2"
                />
              {tags}
            </div>
            <button title={ this.state.expanded ? 'See less' : 'See more' } onClick={this.handleExpand} className="feed-card__expander feed-card__btn--icon w-100 d-flex justify-content-end align-items-start">
              <span className="feed-card__expand feed-card__btn--icon d-block p-3">
                <FontAwesomeIcon icon={expandIcon} />
              </span>
            </button>
          </div>
        </div>
      </div>

     )

  }

  getTimeEmphasis(){
    if(!this.props.data.datetime){
      return null
    }
    const threshold = 24*3600*1000
    const start = new Date(this.props.data.datetime)
    const now = new Date()
    if(Math.abs(start.getTime() - now.getTime()) > threshold){
      return 'date'
    }
    if(this.props.data.endTime){
      const end = new Date(this.props.data.endTime)
      if(Math.abs(end.getTime() - start.getTime()) > threshold){
        return 'date'
      }
    }
    return 'time'
  }

  getEntityLink(){
    return (this.props.entity) ?
      <p className="feed-card__link feed-card__link--entity m-0 text-white">by <Link to={ '/entities/' + this.props.entity.id + '/feed'} className="text-white">{this.props.entity.name}</Link></p>
      : null
  }

  getPerformerElement(){
    const { performer } = this.props.data
    return (performer && performer.name) ?
    <p className="feed-card__link feed-card__link--performer m-0 text-white fst-italic">{performer.name}</p>
    : null
  }

  handleExpand(e){
    e.stopPropagation()
    this.setState({
      expanded: !this.state.expanded
    })
  }

  handleClick(e){
    const {dispatch, history} = this.props
    dispatch(setFeedScrollPosition(this.props.feed, this.props.data.id))
    history.push('/events/' + (this.props.data.alias || this.props.data.id) + '/feed')
  }
}


Card.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const { events: { [ownProps.id] : data}, user: { id: userID }, entities } = state

  const entity = data.entity && data.entity.id && entities[data.entity.id] ? entities[data.entity.id] : null

  const hasTickets = Boolean(getEventOfferings(state, ownProps.id))

  return {
    data,
    entity,
    userID,
    hasTickets
  }
}

export default connect(mapStateToProps)(withRouter(Card))