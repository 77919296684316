import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Total from './Total'
import { getTenantCart } from '../../redux/state'

class CheckoutButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.quantity){
      return null
    }

    const variant = this.props.variant || 'secondary';
    let label = this.props.label || 'Checkout'

    label = <span className="text-nowrap"><span className="d-none d-md-inline">{label}</span> ({this.props.quantity} item{ (this.props.quantity > 1 ? 's' : '') })</span>

    if(this.props.mode === 'inline'){
      return (
        <button title={label} onClick={this.handleClick} className={this.props.className}>
          {label}
        </button>
      )
    }

    if(this.props.mode === 'icon'){
      return (
        <button title={label} onClick={this.handleClick} className={this.props.className}>
          <FontAwesomeIcon icon={faShoppingCart} /><span className="checkout__btn__price ms-2"><Total /></span><span className="checkout__btn__quantity ms-1">({this.props.quantity})</span>
        </button>
      )
    }

    return (
      <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
        <FontAwesomeIcon icon={faShoppingCart} className="me-2" /><span className="text-nowrap">{label}</span>
      </Button>
    )
  }

  handleClick(e){
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }
}

CheckoutButton.propTypes = {
  quantity: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let quantity = 0

  const cart = getTenantCart(state)

  if(cart && cart.items){
    cart.items.map(item => {
      quantity += item.quantity
      return item
    })
  }

  return {
    quantity
  }
}

export default connect(mapStateToProps)(CheckoutButton)