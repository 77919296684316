
/**
 *
 * @param {object} state
 * @param {string} resource
 * @param {string} id
 * @returns {boolean}
 */
export const modalIsVisible = (state, resource, id) => {
  const { modals } = state
  return modals && modals[resource] && modals[resource][id] && modals[resource][id].visible
}