import React from 'react'
import Button from 'react-bootstrap/Button'
import LoginButton from '../auth/LoginButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import CountdownTimer from '../display/CountdownTimer'
import TimeString from '../display/TimeString'
import ChatButton from '../chat/ChatButton'
import BackButton from './BackButton'
import TicketButton from './TicketButton'
import TextBlock from '../display/TextBlock'

import { getStreamEvent, getStreamEventEvent, getStreamEventEntity, getStreamEventAccess, getStreamEventOfferings } from '../../redux/state'

class Preview extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      message: props.message
    }
    this.handleWatchClick = this.handleWatchClick.bind(this)
    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.getActivationButton = this.getActivationButton.bind(this)
    this.getTicketButton = this.getTicketButton.bind(this)

  }

  getChatButton(){
   return this.props.comments ?
    <div className="col-4 col-md-2 text-end">
      <ChatButton
        mode="icon"
        id={this.props.id}
        total={true}
        toggle={true}
        resource="streamevents"
        className="ms-4 preview__comments-btn align-bottom text-white"
        closeIcon="/images/icons/chevron-double-right.svg"
      />
    </div>
    : null
  }

  getSubTitle(){
    const elements = [];
    if(this.props.event){
      elements.push(<span>Part of <Link to={ '/events/' + this.props.event.id + '/feed'} className="text-white">{this.props.event.name}</Link> </span>)
    }
    if(this.props.entity){
      elements.push(<span>by <Link to={ '/entities/' + this.props.entity.id + '/feed'} className="text-white">{this.props.entity.name}</Link> </span>)
    }
    return elements.length ? <p className="preview__subtitle feed-card__link--entity mt-0 mb-3 text-white">{elements}</p> : null
  }

  getActivationButton(){
    const { access, dispatch } = this.props
    if(access && access.status === 'unused'){
      const handleClick = (e) => {
        e.preventDefault()
        dispatch(showModal('access', access.id, true))
      }
      return <Button type="button" variant="outline-light" className="me-2" onClick={handleClick} title="Activate your pass">Activate your pass</Button>

    }
    return null
  }

  getTicketButton(){
    const { hasTickets, access } = this.props
    if(hasTickets && !access){
      return <TicketButton variant="outline-light" id={this.props.id} className="me-2" />
    }
    return null
  }

  render() {

    const { data, access, hasTickets, authenticated } = this.props

    if(!data){
      return null
    }

    const now = new Date()
    const start = new Date(data.datetime)
    const startElement = start ? <h5 className="preview__time mb-0"><TimeString time={start} /></h5> : null
    const countdown = start && start.getTime() > now.getTime() ? <h6 className="mt-1">
        <CountdownTimer
          date={start}
         />
      </h6>
    : null

    const backCss = this.props.comments  ? 'col-3 col-md-4 col-lg-6' : 'col-6 col-lg-8'

    const logo = data.logo ? <img src={data.logo} alt="Logo" /> : null
    const footer = <div className="container-fluid logo preview__footer">
        <div className="container py-5">
          <div className="row align-items-end">
            <div className={backCss}>
              <BackButton className="player__button player__button--back text-white" id={this.props.id} />
            </div>
            <div className="col-5 col-md-6 col-lg-4 px-5 px-md-3 text-end">
              { logo }
            </div>
            {this.getChatButton()}
          </div>
        </div>
      </div>

    let warning
    let buttons = [];

    if(!authenticated && data.access !== 'open'){
      buttons.push(<LoginButton
        variant="outline-light me-2"
        />)
    }

    buttons.push(this.getActivationButton())
    buttons.push(this.getTicketButton())

    if(!data.stream || data.stream.type === 'restricted'){
      if(access && access.status === 'unused'){
        warning = <h3 className="mt-1">Access to this content is restricted. Please activate your pass for access.</h3>
      }else if(data.event && data.event.access === 'registration'){
        warning = <h3 className="mt-1">Access to this content is free but requires registration.</h3>
        buttons.push(<Link to={'/events/' + (data.event.alias || data.event.id) + '/register'} className="btn btn-outline-light me-2">Register now</Link>)
      }else if(hasTickets && !authenticated){
        warning = <h3 className="mt-1">Access to this content is ticketed. If you have a ticket or are on the guest list, please <LoginButton label="sign in for access" inline={true} className="text-white inline-link" />.</h3>
      }else if(hasTickets){
        warning = <h3 className="mt-1">Access to this content is ticketed.</h3>
      }else{
        warning = <h3 className="mt-1">Access to this content is restricted. Please log in or contact the event organiser for access.</h3>
      }
    }

    if(data.details && data.details.url){
      let label = data.details.text || 'More details'
      buttons.push(<Button type="button" variant="outline-light" className="me-2" href={data.details.url} title={label}>{ label }</Button>)
    }

    if((now >= start) && data.stream.type==='url'){
      buttons.push(<Button type="button" variant="outline-light" className="me-2" onClick={this.handleWatchClick} title="Watch now"><FontAwesomeIcon icon={faPlay} className="me-2" /> Watch now</Button>)
    }

    if(buttons.length){
      buttons = <p className="mt-3">
        {buttons}
      </p>
    }

    const description = this.props.fullscreen && data.subtitle ?
      <div className="preview__description pb-4">
        <TextBlock className="my-2" linkClass="text-white" text={data.subtitle} />
      </div>
      : null

    var cssClass = 'w-100 h-100 preview d-flex flex-column justify-content-between'
    if(this.props.fullscreen){
      cssClass += ' fullscreen'
    }

    return (
      <div className={cssClass} style={{ backgroundImage: 'url(' + data.image + ')' }}>
        <div className="container-fluid heading">
          <div className="container py-5 text-white">
              <h1 className="mb-0">{data.title}</h1>
              {this.getSubTitle()}
              {warning}
              {startElement}
              {countdown}
              {buttons}
              {description}
          </div>
        </div>
        {footer}
      </div>
    )
  }

  handleWatchClick(){
    const now = new Date()
    const start = new Date(this.props.data.datetime)
    if((now >= start) && this.props.data.stream.type==='url'){
      window.open(this.props.data.stream.id, this.props.data.title)
    }
  }

  handleLoginClick(e){
    const { dispatch } = this.props
    dispatch(showModal('login',true))
  }
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state

  const data = getStreamEvent(state, ownProps.id)

  const event = getStreamEventEvent(state, ownProps.id)

  const entity = getStreamEventEntity(state, ownProps.id)

  const access = getStreamEventAccess(state, ownProps.id)

  const hasTickets = Boolean(getStreamEventOfferings(state, ownProps.id)) && data.access !== 'open'

  return {
    data,
    event,
    entity,
    access,
    hasTickets,
    authenticated : user && user.status && user.status === 'fetched'
  }
}

export default connect(mapStateToProps)(Preview)