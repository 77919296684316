import serialize from '../../common/serialize'

/**
 * CRUD operations
 */
export const SEND_RESOURCE = 'SEND_RESOURCE'
export const SEND_RESOURCE_ERROR = 'SEND_RESOURCE_ERROR'
export const REQUEST_RESOURCE = 'REQUEST_RESOURCE'
export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE'
export const FETCH_RESOURCE_ERROR = 'FETCH_RESOURCE_ERROR'
export const REQUEST_RESOURCES = 'REQUEST_RESOURCES'
export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES'
export const FETCH_RESOURCES_ERROR = 'FETCH_RESOURCES_ERROR'
export const REQUEST_RESOURCE_DELETION = 'REQUEST_RESOURCE_DELETION'
export const RECEIVE_RESOURCE_DELETION = 'RECEIVE_RESOURCE_DELETION'
export const RESOURCE_DELETION_ERROR = 'RESOURCE_DELETION_ERROR'

export const apiRequest = (service, path, options={}) => {
  const auth = localStorage.getItem(service + 'ApiToken')
  if(auth){
    options.headers = {...options.headers, ...{['Authentication-Info'] : auth }}
  }
  if(!options.credentials){
    options.credentials = 'include'
  }
  if(!options.method){
    options.method = 'GET'
  }
  return fetch(window.endpoint[service] + path, options)
}

export const sendResource = (resource, data) =>{
  return {
    type: SEND_RESOURCE,
    resource,
    data
  }
}

const sendResourceError = (resource, error) => {
  return {
    type: SEND_RESOURCE_ERROR,
    resource,
    error
  }
}

export const post = (resource, data, opts={}) => {
  return (dispatch, getState) => {
    dispatch(sendResource(resource, data))
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({data: {
          type: opts.type || resource,
          attributes: data
        }})
    }

    const path = opts.path || '/' + resource
    return apiRequest(resource, path, requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to post resource');
        }
        if(response.status !== 204){
          return response.json()
        }
        return null
      })
      .then(result => {
        if(result){
          dispatch(receiveResource(result.data.type, result.data.id, result.data))
        }
        return result
      })
      .catch(err => dispatch(sendResourceError(resource, err)))
  }
}

export const patch = (resource, data, opts={}) => {
  return (dispatch, getState) => {

    dispatch(sendResource(resource, data))
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({data: {
          type: opts.type || resource,
          id: data.id,
          attributes: data
        }})
    }

    const path = opts.path || '/' + resource + '/' + data.id
    return apiRequest(resource, path, requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to patch resource ' + data.id);
        }
        if(response.status !== 204){
          return response.json();
        }
        return null
      })
      .then(result => {
        if(result){
          dispatch(receiveResource(result.data.type, result.data.id, result.data))
        }
        return result
      })
      .catch(err => dispatch(sendResourceError(resource, err)))
  }
}

export const requestResource = (resource, id) =>{
  return {
    type: REQUEST_RESOURCE,
    resource,
    id
  }
}

export const receiveResource = (resource, id, data) => {
  return {
    type: RECEIVE_RESOURCE,
    resource,
    id,
    data
  }
}

export const fetchResourceError = (resource, id, error) => {
  return {
    type: FETCH_RESOURCE_ERROR,
    resource,
    id,
    error
  }
}

export const get = (resource, id, opts={reload:false}) => {
  return (dispatch, getState) => {

    if(!opts.reload
      && !opts.path
      && getState()[resource]
      && getState()[resource][id]
      && getState()[resource][id].id
      && getState()[resource][id].__type){
      return Promise.resolve({id, attributes: getState()[resource][id]})
    }
    dispatch(requestResource(resource, id))

    const path = opts.path || '/' + resource + '/' + id
    return apiRequest(resource, path)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to retrieve resource ' + id)
        }
        if(response.status !== 204){
          return response.json()
        }
        return true
      })
      .then(result => {
        if(result && typeof result !== 'boolean'){
          dispatch(receiveResource(result.data.type, result.data.id, result.data))
        }
        return result
      })
      .catch(error => {
        dispatch(fetchResourceError(resource, id, error))
        return null
      })
  }
}

export const requestResources = (resource, filter) =>{
  return {
    type: REQUEST_RESOURCES,
    resource,
    filter,
  }
}

export const receiveResources = (resource, data) => {
  return {
    type: RECEIVE_RESOURCES,
    resource,
    data
  }
}

export const fetchResourcesError = (resource, error) => {
  return {
    type: FETCH_RESOURCES_ERROR,
    resource,
    error
  }
}

export const getMany = (resource, opts={}) => {
  return (dispatch, getState) => {
    dispatch(requestResources(resource, opts.filter))

    let path = opts.path || '/' + resource

    const query = serialize((({ filter='', sort='', fields='', page='', orgs='' }) => ({ filter, sort, fields, page, orgs }))(opts))
    if(query){
      path += '?' + query
    }
    return apiRequest(resource, path)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to retrieve resources')
        }
        if(response.status !== 204){
          return response.json()
        }
        return true
      })
      .then(result => {
        const type = result.data && result.data.length ? result.data[0].type : opts.type || resource
        dispatch(receiveResources(type, result.data))
        return result
      })
      .catch(error => {
        dispatch(fetchResourcesError(resource, error))
        return null
      })
  }
}


export const requestResourceDeletion = (resource, id) =>{
  return {
    type: REQUEST_RESOURCE_DELETION,
    resource,
    id
  }
}

export const receiveResourceDeletion = (resource, id) =>{
  return {
    type: RECEIVE_RESOURCE_DELETION,
    resource,
    id
  }
}

const entityDeletionError = (resource, error) => {
  return {
    type: RESOURCE_DELETION_ERROR,
    resource,
    error
  }
}

export const remove = (resource, id, opts={}) => {
  return (dispatch, getState) => {
    dispatch(requestResourceDeletion(resource, id))

    const requestOptions = {
        method: 'DELETE'
    }

    const path = opts.path || '/' + resource + '/' + id
    return apiRequest(resource, path, requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Unable to delete resource');
        }
        return dispatch(receiveResourceDeletion(resource, id))
      })
      .catch(err => dispatch(entityDeletionError(resource, err)))
  }
}

