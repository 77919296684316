import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SelectorItem from './SelectorItem'
import { getCartShippingOptions } from '../../redux/state'

class Selector extends React.Component {

  render() {

    const { cart, options, handleChange } = this.props

    if(!options){
      return null
    }

    return <div className="shipping shipping__options shipping__options__list">
      {options.map(item => {
        return <SelectorItem key={'ShippingSelectorItem' + item.id } data={item} cart={cart} handleChange={handleChange} className="mb-2" />
      })}
    </div>
  }

}

Selector.propTypes = {
  options: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => {

  return {
    options: getCartShippingOptions(state, ownProps.cart)
  }
}

export default connect(mapStateToProps)(Selector)