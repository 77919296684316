import React from 'react';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TextBlock from '../display/TextBlock'
import TimeRange from '../display/TimeRange'
import TicketButton from './TicketButton'
import EditButton from './EditButton'
import GuestButton from '../guestlist/Button'
import { Link } from 'react-router-dom';
import { getEvent, getEventEntity } from '../../redux/state'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import VenueButton from './VenueButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import UserDownloadButton from './UserDownloadButton'

class Banner extends React.Component {

  render() {

    if(!this.props.data){
      return null
    }

    const start = new Date(this.props.data.datetime)
    let buttons = [], label, timings = [], editButtons = []

    if(start){
      timings.push(<span key="time-range"><TimeRange start={start} end={this.props.data.endTime} emphasis={this.getTimeEmphasis()} /></span>)
    }

    editButtons.push(<GuestButton
      key="guest-button"
      mode="icon"
      resource={{type: 'events', id: this.props.data.id}}
      url={window.location.origin + '/events/' + (this.props.data.alias || this.props.data.id) + '/feed'}
      className="banner__guest banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />)

    editButtons.push(<UserDownloadButton
      key="users-button"
      mode="icon"
      resource={{type: 'events', id: this.props.data.id}}
      className="banner__users banner__btn--icon py-2 ps-2 mx-2 text-white ms-auto"
    />)

    editButtons.push(<EditButton
      key="edit-button"
      mode="icon"
      id={this.props.data.id}
      className="banner__edit banner__btn--icon py-2 ps-2 ms-2 text-white ms-auto"
    />)

    if(editButtons.length){
      editButtons = <p className="banner__start d-flex d-md-block justify-content-between ms-md-auto mt-2 mt-md-0 text-white order-1 order-md-2 text-end">{ editButtons }</p>
    }

    if(this.props.data.details && this.props.data.details.url){
      label = this.props.data.details.text || 'More details'
      buttons.push(<Button key="more-details" type="button" variant="light" href={this.props.data.details.url} className="me-2 mb-2" title="More details">{label}</Button>)
    }
    if(this.props.data.faqs && this.props.data.faqs.url){
      label = this.props.data.faqs.text || 'FAQs'
      buttons.push(<Button key="faqs" type="button" variant="light" href={this.props.data.faqs.url} className="me-2 mb-2" title="Frequently asked questions"><FontAwesomeIcon icon={faQuestionCircle} /><span className="d-none d-md-inline ms-2">{label}</span></Button>)
    }
    buttons.push(<TicketButton key="tickets" resource="events" id={this.props.id} className="me-2 mb-2" />)

    buttons = <span>{buttons}</span>

    const carousel = this.props.data.images && this.props.data.images.length ? this.props.data.images.map(image => image.url) : [this.props.data.image]

    const venue = <VenueButton id={this.props.data.id} className="cursor-pointer text-decoration-none text-white fw-bold" mode="inline" />

    return (
      <div className="banner banner--header position-relative" style={{backgroundImage: 'url(' + this.props.data.image + ')'}}>
        <Carousel fade controls={false} indicators={false} className="position-absolute w-100 h-100">
          {carousel.map((image, index) => <Carousel.Item className="w-100 h-100" interval="6000" style={{backgroundImage: 'url(' +image + ')'}}></Carousel.Item>)}
        </Carousel>
        <div className="banner__inner position-relative">
          <div className="banner__top">
            <div className="banner__controls sticky-top flex-column flex-md-row d-flex justify-content-between align-items-start px-3 pt-3 pb-4 w-100">
               {buttons}
               {editButtons}
            </div>
          </div>
          <div className="banner__bottom">
            <div className="banner__title px-3 pt-2 w-100">
              {this.props.data.logo ?
              <Row>
                <Col
                  xs={1}
                  sm={2}
                  md={3}
                  lg={4}
                />
                <Col
                  xs={10}
                  sm={8}
                  md={6}
                  lg={4}
                >
                  <img src={this.props.data.logo} alt={this.props.data.name} className="w-100" />
                </Col>
                <Col
                  xs={1}
                  sm={2}
                  md={3}
                  lg={4}
                />
              </Row>

              :
              <h1 className="text-white m-0 pt-3">{this.props.data.name}</h1>
              }
              {this.getPerformerElement()}
              {this.getReferenceLinks()}
            </div>
            <div className={ this.props.data.logo ? 'banner__description p-3 w-100 d-flex justify-content-center' : 'banner__description p-3 w-100'} >
              <div className={this.props.data.logo ? 'col-12 col-md-10 col-xl-8 text-center' : 'text-start' }>
                {this.props.data.strapline && (this.props.data.logo || !this.props.data.description) ?
                  <TextBlock
                  key="strapline"
                  text={this.props.data.strapline}
                  className={this.props.data.logo ? 'h3 mt-0 mb-4 pb-2 text-white banner__strapline text-center' : 'm-0 pb-2 text-white banner__strapline'}
                  linkClass="text-white"
                  />
                :
                null
                }
                {timings  ?
                  <p className="m-0 pb-3 text-white">{timings}</p>
                :
                null
                }
                { venue ?
                  <p className="my-3">{ venue }</p>
                :
                null
                }
                <TextBlock
                key="description"
                text={this.props.data.description}
                className="m-0 pb-2 text-white text-align-justify"
                linkClass="text-white"
                />
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }

  getTimeEmphasis(){
    if(!this.props.data.datetime){
      return null
    }
    const threshold = 24*3600*1000
    const start = new Date(this.props.data.datetime)
    const now = new Date()
    if(Math.abs(start.getTime() - now.getTime()) > threshold){
      return 'date'
    }
    if(this.props.data.endTime){
      const end = new Date(this.props.data.endTime)
      if(Math.abs(end.getTime() - start.getTime()) > threshold){
        return 'date'
      }
    }
    return 'time'
  }


  getPerformerElement(){
    const { performer } = this.props.data
    return (performer && performer.name) ?
    <p key="performer" className="feed-card__link feed-card__link--performer m-0 text-white fst-italic">{performer.name}</p>
    : null
  }

  getReferenceLinks(){
    const links = []
    if(this.props.entity){
      //links.push(<React.Fragment key="entity">by <Link to={ '/entities/' + (this.props.entity.alias || this.props.entity.id) + '/feed'} className="text-white"><strong>{this.props.entity.name}</strong></Link></React.Fragment>)
    }
    if(this.props.parent){
      if(this.props.entity){
        //links.push(', ')
      }
      links.push(<React.Fragment key="event">part of <Link to={ '/events/' + (this.props.parent.alias || this.props.parent.id) + '/feed'} className="text-white"><strong>{this.props.parent.name}</strong></Link></React.Fragment>)
    }
    return links.length ?
      <p key="reference" className="banner__link banner__link-entity m-0 text-white">{ links }</p>
      : null
  }

}

Banner.propTypes = {
  data: PropTypes.object.isRequired,
  entity: PropTypes.object,
  parent: PropTypes.object,
  userID: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const { user: { id: userID } } = state

  const data = getEvent(state, ownProps.id)

  const entity = getEventEntity(state, ownProps.id)

  const parent = data && data.parent ? getEvent(state, data.parent) : null

  if(data.datetime && data.duration){
    data.endTime = (new Date((new Date(data.datetime)).getTime() + (data.duration * 60000)))
  }

  return {
    data,
    entity,
    parent,
    userID
  }
}

export default connect(mapStateToProps)(Banner)