import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import { getTenantCart } from '../../redux/state'

class List extends React.Component {

  render() {

    if(!this.props.quantity){
      return <p>There is nothing in your cart.</p>
    }

    return <div className="cart cart__list cart__list--quicklist">
      <p>You have the following item{this.props.quantity > 1 ? 's' : ''} in your cart:</p>
      {this.props.items.map(item => {
        return <ListItem item={item} className="mb-2" />
      })}
    </div>
  }

}

List.propTypes = {
  items: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const cart = getTenantCart(state)

  let quantity = 0
  let total = 0

  if(cart && cart.items){
    cart.items.map(item => {
      quantity += item.quantity
      total += item.quantity * (item.offering.vat ? (100+item.offering.vat)*item.offering.net/100 : item.offering.net)
      return item
    })
  }

  return {
    items: cart ? cart.items : null,
    quantity,
    total
  }
}

export default connect(mapStateToProps)(List)