import {
  UPDATE_CART,
  GIFT,
  SET_GIFT_MESSAGE,
  UNGIFT,
  SET_CART_ADDRESS,
  SET_CART_MAILING_OPT_IN,
  CLEAR_STRIPE_INTENT,
  RECEIVE_STRIPE_INTENT,
  CLEAR_CART,
  CLEAR_CARTS,
  CONVERT_CART,
  SET_CART_STATUS,
  ACCEPT_TERMS,
  RECEIVE_CART_SHIPPING_OPTIONS,
  FETCH_CART_SHIPPING_OPTIONS_ERROR,
  SET_CART_SHIPPING_METHOD,
  SET_CART_OFFER_CODE
} from './actions'

import {
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES
} from '../crud/actions'

export const carts = (
  state = {},
  action
) => {

  if(action.type === CLEAR_CARTS){
    return {}
  }

  const entity = action.data && action.data.attributes && action.data.attributes.entity ? action.data.attributes.entity.id : action.entity

  if(!entity && action.type !== RECEIVE_RESOURCES && !action.id){
    return state
  }

  let cart = { entity: { id: entity }, items: [] }

  if(action.id){
    cart = Object.keys(state).reduce((agg, key) => {
      return agg || (state[key].id === action.id ? {...state[key]} : null)
    }, null) || cart
  }else{
    cart = entity && state[entity] ? {...state[entity]} : cart
  }

  let index

  switch (action.type) {
    case UPDATE_CART:

      const method = /^(\+|-)\d+$/.test(action.quantity) ? 'increment' : (action.quantity ? 'set' : 'remove')
      const quantity = Number(action.quantity)
      if(method === 'remove'){
        cart.items = cart.items.filter(item => {
          return !(item.offering.id === action.offering.id && item.offering.resource.type === action.offering.resource.type && item.offering.resource.id === action.offering.resource.id)
        })
      }else{
        const index = cart.items.findIndex(item => {
          return (item.offering.id === action.offering.id && item.offering.resource.type === action.offering.resource.type && item.offering.resource.id === action.offering.resource.id)
        })
        if(index !== -1){
          cart.items[index].quantity = method === 'increment' ? cart.items[index].quantity + quantity : quantity
          if(!cart.items[index].quantity){
            cart.items.splice(index, 1)
          }
        }else{
          cart.items.push({
            offering: action.offering,
            quantity: quantity
          })
        }
      }

      if(!cart.items.length){
        const { [cart.entity.id]: removed, ...rest} = state
        return rest
      }

      return {...state, ...{ [entity]: cart }}

    case RECEIVE_CART_SHIPPING_OPTIONS:
      if(action.data === true){
        cart.shipping = {...cart.shipping, ...{ unavailable: false, exempt: true, options: [] }}
      }else{
        cart.shipping = {...cart.shipping, ...{ unavailable: false, exempt: false, options: action.data.map(item => ({...item.attributes, ...{id: item.id}})) }}
      }

      const validSelection = cart.shipping.options && cart.shipping.options.reduce((agg, item) => {
        return agg || (item.id === cart.shipping.id)
      }, false)

      if(!validSelection){
        cart.shipping = {...cart.shipping, ...{ id: null, name: null, price: null}}
      }

      const cheapestOptions = cart.shipping.options ? cart.shipping.options.reduce((agg, method) => {
        let found = agg.findIndex(item => item.duration && item.duration === method.duration && item.price.value <= method.price.value)
        if(found !== -1){
          return agg
        }
        found = agg.findIndex(item => item.duration && item.duration === method.duration && item.price.value > method.price.value)
        if(found !== -1){
          agg.splice(found, 1, method)
          return agg
        }
        agg.push(method)
        return agg
      }, []) : []

      if(cheapestOptions.length === 1){
        cart.shipping = {...cart.shipping, ...{ id: cheapestOptions[0].id, name: cheapestOptions[0].name, price: cheapestOptions[0].price}}
      }

      return {...state, ...{ [cart.entity.id]: cart}}

    case SET_CART_SHIPPING_METHOD:
      const shipping = cart.shipping && cart.shipping.options ? cart.shipping.options.reduce((agg, item, index) => {
        return agg || ((item.id === action.method) ? cart.shipping.options[index] : null)
      }, null) : {}
      cart.shipping = {...cart.shipping, ...{ id: shipping.id, name: shipping.name, price: shipping.price}}
      return {...state, ...{ [cart.entity.id]: cart}}

    case FETCH_CART_SHIPPING_OPTIONS_ERROR:
      cart.shipping = {...cart.shipping, ...{ unavailable: true, exempt: false, options: [], id: null, name: null, price: null }}
      return {...state, ...{ [cart.entity.id]: cart}}

    case GIFT:
      index = cart.items.findIndex(item => {
        return (item.offering.gifting && item.offering.gifting.enabled && item.offering.id === action.offering.id && item.offering.resource.type === action.offering.resource.type && item.offering.resource.id === action.offering.resource.id)
      })
      if(index !== -1 && action.user){
        cart.items[index].gifting = {
          ...cart.items[index].gifting,
          ...{
            recipients: (cart.items[index].gifting && cart.items[index].gifting.recipients ? cart.items[index].gifting.recipients : []).concat([action.user]).filter((val, index, arr) => { return arr.findIndex(elem => elem.email === val.email) === index})
          }
        }
      }
      return {...state, ...{ [entity]: cart }}

    case SET_GIFT_MESSAGE:
      index = cart.items.findIndex(item => {
        return (item.offering.gifting && item.offering.gifting.enabled && item.offering.id === action.offering.id && item.offering.resource.type === action.offering.resource.type && item.offering.resource.id === action.offering.resource.id)
      })
      if(index !== -1){
        cart.items[index].gifting = {
          ...cart.items[index].gifting,
          ...{
            message: action.message
          }
        }
      }
      return {...state, ...{ [entity]: cart }}

    case UNGIFT:
      index = cart.items.findIndex(item => {
        return (item.offering.gifting && item.offering.gifting.enabled && item.offering.id === action.offering.id && item.offering.resource.type === action.offering.resource.type && item.offering.resource.id === action.offering.resource.id)
      })
      if(index !== -1 && action.email){
        cart.items[index].gifting.recipients = (cart.items[index].gifting && cart.items[index].gifting.recipients ? cart.items[index].gifting.recipients : []).filter((val) => { return val.email !== action.email})
      }
      return {...state, ...{ [entity]: cart }}

    case SET_CART_ADDRESS:
      cart.address = {
        ...cart.address,
        ...{
          [action.address] : action.location
        }
      }
      return {...state, ...{ [entity]: cart }}

      case SET_CART_MAILING_OPT_IN:
      cart.mailinglist = {
        ...cart.mailinglist,
        ...{
          optin : action.optin
        }
      }
      return {...state, ...{ [entity]: cart }}

    case SET_CART_STATUS:
      cart.status = action.status
      return {...state, ...{ [entity]: cart }}

    case SET_CART_OFFER_CODE:
      if(action.code){
        cart.offer = { code: action.code }
      }else{
        cart.offer = {}
      }
      return {...state, ...{ [entity]: cart }}


    case ACCEPT_TERMS:
      cart.terms = { accepted: action.accept }
      return {...state, ...{ [entity]: cart }}

    case RECEIVE_RESOURCES:
      if(action.resource && action.resource !== 'carts'){
        return state
      }
      return action.data.reduce((map, item) => {
        if(item.attributes.status === 'open' && item.attributes.entity){
          map[item.attributes.entity.id] = {
            ...state[item.attributes.entity.id] || {},
            ...item.attributes,
            ...{id: item.id},
            ...{__type: item.type}
          }
        }
        return map
      }, {})


    case RECEIVE_RESOURCE:
      if(action.resource && action.resource !== 'carts'){
        return state
      }
      if(action.data.attributes.status !== 'open'){
        return state
      }
      const attributes = {
        ...action.data.attributes,
        ...{ shipping: {
          ...action.data.attributes.shipping,
          ...{ options: cart.shipping ? cart.shipping.options : null }
        }}
      }
      return {
        ...state,
        ...{[entity]: {
          ...cart,
          ...attributes,
          ...{id: action.data.id}
          }
        }
      }

    case CLEAR_CART:
      const { [entity]: removed, ...carts} = state
      return carts

    case RECEIVE_STRIPE_INTENT:
      return {
        ...state,
        ...{[entity]: {
          ...cart,
          ...{
            intents: {
                stripe: action.data
              }
            }
          }
        }
      }

    case CLEAR_STRIPE_INTENT:
      return {
        ...state,
        ...{[entity]: {
          ...cart,
          ...{
            intents: {}
            }
          }
        }
      }

    default:
      return state
  }
}

export const orders = (
  state = {},
  action
) => {
  if(action.resource && action.resource !== 'orders'){
    return state
  }

  if(action.type === CLEAR_CARTS){
    return {}
  }

  switch (action.type) {

    case RECEIVE_RESOURCES:
      return action.data.reduce((map, item) => {
        map[item.id] = {
          ...(state[item.id] || {}),
          ...item.attributes,
          ...{id: item.id},
          ...{__type: item.type}
        }
        return map
      }, {...state})

    case RECEIVE_RESOURCE:
      const data = {...state, ...{[action.id]: {
        ...(state[action.id] || {}),
        ...action.data.attributes,
        ...{__type: action.data.type},
        ...{id: action.data.id}
      }}}
      return data

    case CONVERT_CART:
      return {
        ...state,
        ...{[action.cart.id]: action.cart}
      }

    default:
      return state
  }
}

export const gifts = (
  state = {},
  action
) => {

  if(action.resource && action.resource !== 'gifts'){
    return state
  }

  switch (action.type) {

    case RECEIVE_RESOURCES:
      const map = {}
      action.data.map((gift) => {
        const data = {
          ...(state[gift.id] || {}),
          ...gift.attributes,
          ...{id: gift.id},
          ...{__type: gift.type}
        }
        data.offering.gifting.id = data.id
        data.offering.gifting.originator = data.originator
        map[data.offering.resource.type] = {
          ...map[data.offering.resource.type],
          ...{
            [data.offering.resource.id]: {
              offerings: [
                ...(map[data.offering.resource.type] && map[data.offering.resource.type][data.offering.resource.id] ? map[data.offering.resource.type][data.offering.resource.id].offerings : []),
                ...[data]
              ]
            }
          }
        }
        return data
      })
      return map

    default:
      return state
  }
}