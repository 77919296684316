import React from 'react'
import BootstrapCarousel from 'react-bootstrap/Carousel'
import { connect } from 'react-redux'
import { getProduct } from '../../redux/state'
import PropTypes from 'prop-types'
import { setFeedScrollPosition } from '../../redux/feed/actions'

class Carousel extends React.Component {

  render(){

    const { data } = this.props

    if(!data || !data.images || !data.images.length){
      return null
    }

    return <BootstrapCarousel interval={null} className={this.props.className}>
    {data.images.map(image => (
      <BootstrapCarousel.Item>
        <div className="carousel-image px-2">
          <img
            className="d-block mx-auto mt-3 mb-4"
            src={image.url}
            alt={image.caption}
            title={image.caption}
            role="button"
            onClick={this.props.handleClick}
          />
        </div>
      </BootstrapCarousel.Item>
    ))}
    </BootstrapCarousel>

  }

}


Carousel.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  return {
    data: getProduct(state, ownProps.id)
  }
}

export default connect(mapStateToProps)(Carousel)