import { patch, post, getMany } from '../crud/actions'

/**
 * USER
 */
export const REQUEST_USER_ACCESS_PASSES = 'REQUEST_USER_ACCESS_PASSES'
export const FETCH_USER_ACCESS_PASS_ERROR = 'FETCH_USER_ACCESS_PASS_ERROR'
export const RECEIVE_USER_ACCESS_PASSES = 'RECEIVE_USER_ACCESS_PASSES'
export const ACTIVATE_USER_ACCESS_PASSES = 'ACTIVATE_USER_ACCESS_PASSES'
export const ACTIVATE_USER_ACCESS_PASS_ERROR = 'ACTIVATE_USER_ACCESS_PASS_ERROR'
export const RECEIVE_USER_ACCESS_PASS = 'RECEIVE_USER_ACCESS_PASS'
export const CLEAR_USER_ACCESS_PASSES = 'CLEAR_USER_ACCESS_PASSES'
export const RESEND_USER_ACCESS_PASSES = 'RESEND_USER_ACCESS_PASSES'
export const RESEND_USER_ACCESS_PASS_ERROR = 'RESEND_USER_ACCESS_PASS_ERROR'


export const requestUserAccessPasses = () =>{
  return {
    type: REQUEST_USER_ACCESS_PASSES,
  }
}

export const receiveUserAccessPasses = (data) => {
  return {
    type: RECEIVE_USER_ACCESS_PASSES,
    data
  }
}

export const fetchUserAccessPassError = (error) => {
  return {
    type: FETCH_USER_ACCESS_PASS_ERROR,
    error
  }
}

export const activateUserAccessPasses = (id) =>{
  return {
    type: ACTIVATE_USER_ACCESS_PASSES,
    id
  }
}

export const receiveUserAccessPass = (id, data) => {
  return {
    type: RECEIVE_USER_ACCESS_PASS,
    id,
    data
  }
}

export const activateUserAccessPassError = (id, error) => {
  return {
    type: ACTIVATE_USER_ACCESS_PASS_ERROR,
    id,
    error
  }
}

export const clearUserAccessPasses = () =>{
  return {
    type: CLEAR_USER_ACCESS_PASSES,
  }
}

export const fetchUserAccessPasses = () => {
  return (dispatch, getState) => {
    dispatch(requestUserAccessPasses())
    const id = getState().user.id
    if(!id){
      dispatch(fetchUserAccessPassError(new Error('No user id found')))
    }else{
      return dispatch(getMany('tickets', { path: '/users/' + id + '/accesses', type: 'accesses', filter: { status: ['unused', 'activated']} }))
        .then(result => {
          if(result && result.data){
            dispatch(receiveUserAccessPasses(result.data))
            return result
          }
          dispatch(fetchUserAccessPassError(new Error('No user access pass retreived')))
        })
    }
  }
}

export const activatePass = (id) => {
  return (dispatch, getState) => {
    dispatch(activateUserAccessPasses(id))
    return dispatch(patch('tickets', {}, { path: '/access/' + id + '/activation'}))
      .then((result) => {
        dispatch(receiveUserAccessPass(id, result.data))
      })
      .catch((err) => {
        dispatch(activateUserAccessPassError(err))
      })
  }
}

export const resendUserAccessPasses = (entity, user) =>{
  return {
    type: RESEND_USER_ACCESS_PASSES,
    entity,
    user
  }
}

export const resendUserAccessPassError = (entity, user, error) => {
  return {
    type: RESEND_USER_ACCESS_PASS_ERROR,
    entity,
    user,
    error
  }
}

export const resendUserPasses = (entity, user) => {
  return (dispatch, getState) => {
    dispatch(resendUserAccessPasses(entity, user))
    return dispatch(post('tickets', {}, { path: `/entities/${entity}/users/${user}/accesses/email`}))
      .then((result) => {
        return true
      })
      .catch((err) => {
        dispatch(resendUserAccessPassError(entity, user, err))
        return false
      })
  }
}