import React from 'react';
import Menu from '../navigation/Menu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { fetchUser } from '../../redux/user/actions'
import PropTypes from 'prop-types'
import Footer from '../navigation/Footer'
import List from '../order/List'
import { fetchUserOrders } from '../../redux/shopping/actions'

class OrderWindow extends React.Component {

  componentDidMount() {

    const { dispatch } = this.props
    //dispatch(setCurrentTenant('entities', 'zarucchi'))

    dispatch(fetchUser(this.props.id))
      .then(()=>{
        dispatch(fetchUserOrders(this.props.id, true))
      })

  }

  render() {

    if(!this.props.data){
      return null
    }

    return (
      <React.Fragment>
        <Container className="bg-white order-window">
          <Menu />

            <Row>
              <Col className="pt-2 pb-2">
                <h1 className="display-2">Your orders</h1>
              </Col>
            </Row>

            <div className="order-container">
              <Row>
                <Col>
                  <List />
                </Col>
              </Row>
            </div>
        </Container>
        <Footer />
      </React.Fragment>
    )

  }

}

OrderWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { users, user:current, orders: data } = state

  const user = current && current.id ? users[current.id] : null

  return {
    id,
    authenticated: user && user.status && user.status === 'fetched',
    data,
    user
  }
}

export default connect(mapStateToProps)(OrderWindow)