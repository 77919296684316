import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import GiftListItem from './GiftListItem'
import { getTenantCart } from '../../redux/state'

class GiftList extends React.Component {

  render() {

    if(!this.props.giftable || !this.props.giftable.length){
      return null
    }

    return <div className="cart cart__list cart__list--giftable">
      <p>You can offer {this.props.giftable.length > 1 ? 'the following tickets' : 'this ticket'} to up to 5 friends or family members at a discounted price:</p>
      {this.props.giftable.map(item => {
        return <GiftListItem item={item} className="mb-2" />
      })}
    </div>
  }

}

GiftList.propTypes = {
  giftable: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const cart = getTenantCart(state)

  const giftable = cart && cart.items ? cart.items.filter(item => {
      return (item.offering.gifting && item.offering.gifting.enabled)
    }) : null

  return {
    giftable
  }
}

export default connect(mapStateToProps)(GiftList)