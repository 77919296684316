import React from 'react';
import Button from 'react-bootstrap/Button';
import { showModal } from '../../redux/ui/actions'
import { useDispatch, useSelector } from 'react-redux'
import { modalIsVisible } from '../../redux/ui/state';
import { useHistory } from 'react-router';

export default props => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { resource, id, variant='primary', label, mode='button', className, icon, size} = props

  const shown = useSelector(state => modalIsVisible(state, resource, id))

  const handleClick = (e) => {
    const { hash, pathname, search } = history.location
    if(!shown){
      history.push(`${pathname}${search}#modal-${resource}-${id}`)
    }else{
      history.push(`${pathname}${search}`)
    }
    dispatch(showModal(resource, id, !shown))
  }

  switch(mode){
    case 'text':
      return (
        <button title={label} type="button" onClick={handleClick} className={className}>
          {label}
        </button>
      )

    case 'icon':
      return (
        <button title={label} type="button" onClick={handleClick} className={className}>
          {icon}
        </button>
      )

    default:
      if(icon){
        return (
          <Button variant={variant} title={label} onClick={handleClick} className={className} size={size}>
            <span className="d-inline d-md-none">{icon}</span><span className="d-none d-md-inline text-nowrap">{label}</span>
          </Button>
        )
      }
      return (
        <Button variant={variant} title={label} onClick={handleClick} className={className} size={size}>
          <span className="text-nowrap">{label}</span>
        </Button>
      )
  }

}