import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import ImageSelector from '../form/ImageSelector'
import TagSelector from '../form/TagSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import AddressFields from '../form/AddressFields'
import { connect } from 'react-redux'
import {
  postEntity,
  patchEntity,
  fetchEntity,
  fetchEntityOwners,
  fetchEntityAdministrators,
  fetchEntityUsers,
  fetchEntityCreator
} from '../../redux/entity/actions'
import { postLocation } from '../../redux/location/actions'
import PropTypes from 'prop-types'
import Accordion from 'react-bootstrap/Accordion'
import AccordionToggle from '../form/AccordionToggle'
import Card from 'react-bootstrap/Card'
import ModalButton from '../display/ModalButton'
import Modal from '../display/Modal'
import InvitationForm from './InvitationForm'
import ECommerceForm from './ECommerceForm'
import MailchimpForm from './MailchimpForm'
import OrderDownloadButton from './OrderDownloadButton'
import UserList from '../user/UserList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faImage, faThList, faMapMarkerAlt, faUserFriends, faRegistered, faCreditCard, faShoppingCart, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faMailchimp } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from 'react-router'
import { getEntityAncestors } from '../../redux/state'
import EntitySelector from './EntitySelector'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        name: '',
        alias: '',
        parent: '',
        website: '',
        address: {},
        logo: '',
        colors: {
          primary: ''
        },
        feed: {},
        tags: [],
        company: {
          number: '',
          vat: '',
          address: {}
        }
      },
      validated: false,
      changed: false,
      aliasFeedback:'Please enter the organisation\'s alias.'
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleProductTagChange = this.handleProductTagChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.validateAlias = this.validateAlias.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
    this.getUserSettings = this.getUserSettings.bind(this)
    this.getECommerceSettings = this.getECommerceSettings.bind(this)
    this.getMailchimpSettings = this.getMailchimpSettings.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id && this.props.id !== 'new'){
      dispatch(fetchEntity(this.props.id, true))
        .then(result => {
          if(this.props.data){
            this.setState({
              data: this.props.data
            })
          }
        })
    }
    Promise.resolve()
      .then(() => {
        if(this.props.creator && this.props.id !== 'new'){
          return dispatch(fetchEntityOwners(this.props.id))
        }
        return Promise.resolve()
      })
      .then(() => {
        if(this.props.owner && this.props.id !== 'new'){
          return dispatch(fetchEntityAdministrators(this.props.id))
        }
        return Promise.resolve()
      })
      .then(() => {
        if(this.props.owner && this.props.id !== 'new'){
          return dispatch(fetchEntityCreator(this.props.id))
        }
        return Promise.resolve()
      })
      .then(() => {
        if(this.props.administrator && this.props.id !== 'new'){
          return dispatch(fetchEntityUsers(this.props.id))
        }
        return Promise.resolve()
      })
      .catch(err => console.log(err.message))

    $('.collapse button[value="save"]', $(this.el)).on('blur', (e) => {
      $(e.target).parents('.collapse').first().removeClass('show')
      let index = $(e.target).parents('.card').first().index()
      index = index + 1 > $(e.target).parents('.card').first().siblings().length ? 0 : index + 1
      $('.collapse', $('.card', $(e.target).parents('.accordion').first()).get(index)).addClass('show')
      $('input, select, textarea', $('.card', $(e.target).parents('.accordion').first()).get(index)).first().trigger('focus')
    })

    $('.card input, .card select, .card textarea', $(this.el)).first().trigger('focus')

  }

  componentWillUnmount() {
    $('.collapse button[value="save"]', $(this.el)).off('blur')
  }

  handleInputChange(event) {
    const name = event.target.name
    let value = event.target.value
    if(name === 'alias'){
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    this.setValue(name, value)
  }

  handleTagChange(tags){
    tags = tags.map(tag => tag.text)
    this.setValue('tags', tags)
  }

  handleProductTagChange(tags){
    tags = tags.map(tag => tag.text)
    this.setValue('product_tags', tags)
  }

  setValue(name, value){
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if(name==='name' && !this.props.data){
      data.alias = value.replace(/\W+/g, '-').toLowerCase()
    }
    this.setState({
      data: data,
      validated: false,
      changed: true,
      feedbackMessage: ''
    })
    if(data.alias){
      this.validateAlias(data.alias)
        .then(validated => {
          this.setState({
            validated: !validated,
            aliasFeedback: validated ? 'Please enter the organisation\'s alias.' : 'This alias is already in use.'
          })
          $('#EntityForm input[name="alias"]').get(0).setCustomValidity(validated ? '' : 'Invalid field')
        })
    }
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  validateAlias(alias){
    if(this.props.data && this.props.data.alias && this.props.data.alias === alias){
      return Promise.resolve(true)
    }
    return new Promise((resolve, reject) => {
      const url = window.endpoint.entities + '/entities/' + alias + '/existence'
      fetch(url)
        .then(response => {
          if(response.ok){
            throw new Error('Alias exists')
          }
          resolve(true)
        })
        .catch(error => {
          resolve(false)
        })
    })
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      let invalid = form.querySelectorAll(':invalid');
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const data = {...this.state.data}
    const { dispatch } = this.props
    // Submit address
    Promise.resolve()
    .then(result => {
      if(data.address && data.address instanceof Object && data.address.postcode){
        return dispatch(postLocation({ address: data.address }))
      }
      return null
    })
    .then(result => {
      if(result){
        data.address = result.data.id
      }else if(data.address){
        data.address = ''
      }
      if(data.company && data.company.address && data.company.address instanceof Object && data.company.address.postcode){
        return dispatch(postLocation({ address: data.company.address }))
      }
      return null
    })
    .then(result => {
      if(result){
        data.company.address = result.data.id
      }else if(data.company && data.company.address){
        data.company.address = ''
      }
      return true
    })
    .then(result => {
      if(this.props.data && data.action !== 'new'){
        return dispatch(patchEntity(data))
      }else{
        delete data.id
        delete data.action
        return dispatch(postEntity(data))
      }
    })
    .then(result => {
      this.handleSaveSuccess(result)
    })
    .catch(err => {
      this.setState({
        validated: false,
        feedbackMessage: err.message
      })
      console.log(err.message)
    })
  }

  handleSaveSuccess(result){
    this.setState({
      validated: false,
      changed: false,
      feedbackMessage: 'Organisation details saved successfully'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(result)
    }
    if(this.props.id === 'new'){
      const { history } = this.props
      history.push('/entities/' + result.data.id + '/edit')
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  getUserSettings(){

    if(this.props.id === 'new' || !this.props.owner){
      return null
    }

    const userForm = <InvitationForm id={this.props.id} />
    const headerClass = 'text-secondary'

    return <Card>
        <AccordionToggle className={headerClass} variant="link" eventKey="8">
        <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faUserFriends} /></span>
          Users
        </AccordionToggle>
      <Accordion.Collapse eventKey="8">
        <div>
          <Card.Body>
            <UserList resource="entities" id={this.props.id} />
          </Card.Body>
          <Card.Footer className="text-end mt-2">
            <ModalButton
              resource="users"
              id="invitation"
              label="Invite a user" />
          </Card.Footer>
        </div>
      </Accordion.Collapse>
      <Modal
        resource="users"
        id="invitation"
        title="Invite a new user"
        content={userForm}
        />
    </Card>
  }

  getECommerceSettings(){

    if(this.props.id === 'new' || !this.props.owner){
      return null
    }

    const headerClass = 'text-secondary'

    return <Card>
        <AccordionToggle className={headerClass} variant="link" eventKey="9">
        <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faCreditCard} /></span>
          E-Commerce
        </AccordionToggle>
      <Accordion.Collapse eventKey="9">
        <div>
          <Card.Body>
            <ECommerceForm id={this.props.id} />
            <OrderDownloadButton id={this.props.id} size="sm" />
          </Card.Body>
        </div>
      </Accordion.Collapse>
    </Card>
  }

  getMailchimpSettings(){

    if(this.props.id === 'new' || !this.props.administrator){
      return null
    }

    const headerClass = 'text-secondary'

    return <Card>
        <AccordionToggle className={headerClass} variant="link" eventKey="10">
        <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faMailchimp} /></span>
          Mailchimp
        </AccordionToggle>
      <Accordion.Collapse eventKey="10">
        <div>
          <Card.Body>
            <MailchimpForm id={this.props.id} />
          </Card.Body>
        </div>
      </Accordion.Collapse>
    </Card>
  }

  render() {

    const { id, administrator, entities, data } = this.props

    if(!administrator && !(id === 'new' && Object.keys(entities).length)){
      return null
    }

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    const tags = (data && data.tags) ? data.tags.map(tag => { return { id: tag, text: tag}}) : []
    const productTags = (data && data.product && data.product.tags) ? data.product.tags.map(tag => { return { id: tag, text: tag}}) : []

    const headerClass = 'text-secondary'

    return (
      <Form ref={el => this.el = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="EntityForm" className={this.props.className}>
        <Accordion defaultActiveKey="0">
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="0">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faBuilding} /></span>
                Organisation Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <EntitySelector
                    id="EntityParentInput"
                    entity={id}
                    name="parent"
                    onChange={this.handleInputChange}
                    value={this.getValue('parent')}
                    allowNull={true}
                    label="Parent organisation"
                  />
                <Form.Group controlId="EntityNameInput">
                  <Form.Label className="mt-2 mb-0">Name</Form.Label>
                  <Form.Control name="name" onChange={this.handleInputChange} placeholder="The organisation's title" value={this.getValue('name')} required />
                  <Form.Control.Feedback type="invalid">
                    Please enter the organisation's name
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="EntityAliasInput">
                  <Form.Label className="mt-2 mb-0">Alias</Form.Label>
                  <Form.Control name="alias" onChange={this.handleInputChange} placeholder="The organisation's alias" value={this.getValue('alias')} required />
                  <Form.Control.Feedback type="invalid">
                    { this.state.aliasFeedback }
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    A short version of the organisation's name to use in page links and URLs
                  </Form.Text>
                </Form.Group>

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="1">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faImage} /></span>
                Display Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Form.Group controlId="EntityLogoInput">
                  <Form.Label className="mt-2 mb-0">Logo</Form.Label>
                    <div className="input-group align-items-start" id="EntityLogoInput" data-target-input="nearest">
                      <ImageSelector
                        image={this.getValue('logo')}
                        name="logo"
                        type="logo"
                        handleChange={this.setValue}
                        />
                    </div>
                  <Form.Text className="text-muted">
                    Used for page headers
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityIdentInput">
                  <Form.Label className="mt-2 mb-0">Ident</Form.Label>
                    <div className="input-group align-items-start" id="EntityIdentInput" data-target-input="nearest">
                      <ImageSelector
                        image={this.getValue('ident')}
                        name="ident"
                        type="logo"
                        handleChange={this.setValue}
                        />
                    </div>
                    <Form.Text className="text-muted">
                      A reversed logo for overlaying dark backgrounds e.g. banner images and holding pages
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityFaviconInput">
                  <Form.Label className="mt-2 mb-0">Favicon</Form.Label>
                    <div className="input-group align-items-start" id="EntityFaviconInput" data-target-input="nearest">
                      <ImageSelector
                        image={this.getValue('favicon')}
                        name="favicon"
                        type="logo"
                        handleChange={this.setValue}
                        />
                    </div>
                  <Form.Text className="text-muted">
                    For best results use a square PNG with a transparent background of at least 192px x 192px
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityNameInput">
                  <Form.Label className="mt-2 mb-0">Primary colour</Form.Label>
                  <Form.Control name="colors_primary" onChange={this.handleInputChange} placeholder="A hex value of the organisation's primary colour" value={this.getValue('colors.primary')} pattern="^#([0-9A-F]{3}){1,2}$" />
                  <Form.Control.Feedback type="invalid">
                    This must be a hexadecimal value in the format '#AABBCC'
                  </Form.Control.Feedback>
                 <Form.Text className="text-muted">
                    Used to add colour to page elements, e.g. buttons
                  </Form.Text>
                </Form.Group>

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="2">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faThList} /></span>
                Feed Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <Form.Group controlId="EntityCoverInput">
                  <Form.Label className="mt-2 mb-0">Cover image</Form.Label>
                    <div className="input-group align-items-start" id="EntityCoverInput" data-target-input="nearest">
                      <ImageSelector
                        image={this.getValue('cover')}
                        name="cover"
                        handleChange={this.setValue}
                        />
                    </div>
                  <Form.Text className="text-muted">
                    Used as a background image for the feed header
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityFeedIntroductionInput">
                  <Form.Label className="mt-2 mb-0">About</Form.Label>
                  <Form.Control name="feed_introduction" as="textarea" rows="4" onChange={this.handleInputChange} placeholder="Information about the organisation" value={this.getValue('feed.introduction')} />
                </Form.Group>

                <Form.Group controlId="EntityFeedContentInput">
                  <Form.Label className="mt-2 mb-0">Content</Form.Label>
                  <CheckboxGroup
                    options={[
                      { value: 'entities', label: 'Sub-organisations'},
                      { value: 'events', label: 'Events'}
                    ]
                    }
                    name="feed_content"
                    value={this.getValue('feed.content')}
                    onChange={this.setValue}
                    />
                </Form.Group>


                <Form.Group controlId="EntityFeedSortInput">
                  <Form.Label className="mt-2 mb-0">Feed order</Form.Label>
                  <Form.Control as="select" name="feed_sort" onChange={this.handleInputChange} value={this.getValue('feed.sort')}>
                    <option value="datetime">Event times</option>
                    <option value="-created">Latest post</option>
                    <option value="title">Alphabetical</option>
                  </Form.Control>
                  <Form.Text className="text-muted">
                    Dictates how items will ordered in the organisation's feed
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityFeedGroupingInput">
                  <Form.Label className="mt-2 mb-0">Grouping</Form.Label>
                  <Form.Control as="select" name="feed_grouping" onChange={this.handleInputChange} value={this.getValue('feed.grouping')}>
                    <option value="">Not grouped</option>
                    <option value="minute">Minute</option>
                    <option value="hour">Hour</option>
                    <option value="day">Day</option>
                  </Form.Control>
                  <Form.Text className="text-muted">
                    If the feed is ordered by event times, its items can be grouped into time slots by minute, hour or day
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityFeedCssInput">
                  <Form.Label className="mt-2 mb-0">Styles</Form.Label>
                  <Form.Control name="feed_css" as="textarea" rows="3" onChange={this.handleInputChange} placeholder="Custom CSS for the organisation's feed" value={this.getValue('feed.css')} />
                 <Form.Text className="text-muted">
                   Customise the look of the organisation's feed page using <a href="https://www.w3.org/Style/CSS/learning" rel="noopener noreferrer" target="_blank">cascading stylesheets</a>
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityTagsInput">
                  <Form.Label className="mt-2 mb-0">Tags</Form.Label>
                  <TagSelector
                    tags={tags}
                    handleChange={this.handleTagChange}
                  />
                  <Form.Text className="text-muted">
                    Tags can be used to filter the contents of the organisation's feed
                  </Form.Text>
                  </Form.Group>

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="3">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faShoppingCart} /></span>
                Product Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>

                <Form.Group controlId="EntityProductEnabledInput">
                  <Form.Check type="checkbox" label="Enabled" name="product_enabled" value="1" onChange={this.handleInputChange} checked={this.getValue('product.enabled')} />
                </Form.Group>

                <Form.Group controlId="EntityProductTagsInput">
                  <Form.Label className="mt-2 mb-0">Tags</Form.Label>
                  <TagSelector
                    tags={productTags}
                    handleChange={this.handleProductTagChange}
                  />
                  <Form.Text className="text-muted">
                    Tags can be used to filter your organisation's products
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="EntityProductSubTitleInput">
                  <Form.Label className="mt-2 mb-0">Sub-title</Form.Label>
                  <Form.Control name="product_subtitle" onChange={this.handleInputChange} placeholder="Sub-title label" value={this.getValue('product.subtitle')} />
                  <Form.Text className="text-muted">
                    The label of the product specification to be used as a sub-title in the product listings, e.g. 'Manufacturer' or 'Author'
                  </Form.Text>
                </Form.Group>

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="4">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                Address Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <Form.Group controlId="EntityWebsiteInput">
                  <Form.Label className="mt-2 mb-0">Website</Form.Label>
                  <Form.Control name="website" onChange={this.handleInputChange} placeholder="The URL of the organisation's website" value={this.getValue('website')} type="url" />
                  <Form.Control.Feedback type="invalid">
                    This must be a full URL, including http:// or https:// at the beginning
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="EntityEmailInput">
                  <Form.Label className="mt-2 mb-0">E-mail</Form.Label>
                  <Form.Control name="email" onChange={this.handleInputChange} placeholder="A contact e-mail address for the organisation" value={this.getValue('email')} type="email" />
                  <Form.Control.Feedback type="invalid">
                    Please supply a valid e-mail address
                  </Form.Control.Feedback>
                </Form.Group>

                <AddressFields handleChange={ (data) => this.setValue('address', data) } id={data ? data.address : null} />

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="5">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faRegistered} /></span>
                Company Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <Form.Group controlId="EntityCompanyNoInput">
                  <Form.Label className="mt-2 mb-0">Registered company number</Form.Label>
                  <Form.Control name="company_number" onChange={this.handleInputChange} placeholder="The registered company's number" value={this.getValue('company.number')} />
                </Form.Group>

                <Form.Group controlId="EntityCompanyVATInput">
                  <Form.Label className="mt-2 mb-0">VAT registration number</Form.Label>
                  <Form.Control name="company_vat" onChange={this.handleInputChange} placeholder="The company's VAT registration number" value={this.getValue('company.vat')} />
                </Form.Group>

                <h6 className="mt-3">Registered office</h6>

                <AddressFields handleChange={ (data) => this.setValue('company_address', data) } id={data && data.company ? data.company.address : null} />

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="6">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faFacebook} /></span>
                Facebook Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                <p className="my-2">To record PageView, AddToCart and Purchase events, add your Facebook Pixel ID below:</p>

                <Form.Group controlId="EntityFacebookPixelIDInput">
                  <Form.Label className="mt-2 mb-0">Pixel ID</Form.Label>
                  <Form.Control name="facebook_pixel_id" onChange={this.handleInputChange} placeholder="Facebook pixel ID" value={this.getValue('facebook.pixel.id')} />
                </Form.Group>

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
              <AccordionToggle className={headerClass} variant="link" eventKey="7">
              <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={faPaperPlane} /></span>
                Sign-up Settings
              </AccordionToggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                <p className="my-2">If you have a script for a mailing list sign-up pop-up, add it here</p>

                <Form.Group controlId="EntityMailingListSrcInput">
                  <Form.Label className="mt-2 mb-0">URL</Form.Label>
                  <Form.Control name="mailinglist_popup_src" onChange={this.handleInputChange} placeholder="Script URL" value={this.getValue('mailinglist.popup.src')} />
                </Form.Group>

                <div className="text-end mt-2">
                  <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                    Save
                  </Button>
                </div>

              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {this.getUserSettings()}
          {this.getECommerceSettings()}
          {this.getMailchimpSettings()}
        </Accordion>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

      </Form>
    )
  }
}

EditForm.propTypes = {
  creator: PropTypes.bool,
  owner: PropTypes.bool,
  administrator: PropTypes.bool,
  data: PropTypes.object,
  entities: PropTypes.object,
  platform: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {

  let { entities: {[ownProps.id]: data}, user } = state

  let entities = {}

  let administrator = user && user.platform && user.platform.support

  if(user && user.administrator && user.administrator.entities && Array.isArray(user.administrator.entities)){
    user.administrator.entities.map((id, index) => {
      if(id !== ownProps.id){
        if(!getEntityAncestors(state, id).map(item => item.id).includes(ownProps.id)){
          entities[id] = state.entities[id]
        }
      }else{
        administrator = true
      }
      return id
    })
  }

  const owner = (user && user.owner && user.owner.entities && Array.isArray(user.owner.entities) && user.owner.entities.includes(ownProps.id))

  const creator = (user && user.id && data && data.owner && data.owner.id && data.owner.id === user.id)

  return {
    creator,
    owner,
    administrator,
    data,
    entities,
    platform: user.platform
  }
}

export default connect(mapStateToProps)(withRouter(EditForm))