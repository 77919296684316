
/**
 * Returns a stored shopping cart object for the specified entity
 * @param {object} state
 * @param {string} entity
 * @returns
 */
export const getEntityCart = (state, entity) => {
  if(!state
    || !state.carts
    || !state.carts[entity]){
      return null
    }
  return state.carts[entity]
}

/**
 * Returns the quantity of an item a user has in their cart
 * @param {object} state
 * @param {string} entity
 * @param {object} offering
 * @returns {number}
 */
export const getCartItemQuantity = (state, entity, offering) => {
  const cart = getEntityCart(state, entity)
  if(!cart || !cart.items) return 0
  const item = cart.items.find(item => item.offering.id === offering.id && item.offering.resource.type === offering.resource.type && item.offering.resource.id === offering.resource.id)
  if(!item) return 0
  return item.quantity
}