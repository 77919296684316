import styles from './SettingsWindow.module.scss'
import React from 'react';
import LoginModal from '../auth/LoginModal';
import Menu from '../navigation/Menu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { setCurrentTenant } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import SettingsMenu from './Menu'
import Breadcrumb from '../navigation/Breadcrumb'
import Entities from '../entity/Settings'
import Events from '../event/Settings'
import Venues from '../venue/Settings'
import Domains from '../domain/Settings'
import Footer from '../navigation/Footer'
import {trackPageView, initGA} from '../tracking/Google'

class SettingsWindow extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(setCurrentTenant('entities', 'zarucchi'))
    document.title = 'Zarucchi Settings'

    initGA(window.gaTrackingCode)
    trackPageView()
  }

  renderContent(group) {

    if(!this.props.authenticated){
      return null;
    }

    switch(group) {
      case 'events':
        return <Events />;
      case 'entities':
        return <Entities />;
      case 'venues':
        return <Venues />;
      case 'domains':
        return <Domains />;
      default:
        return <SettingsMenu className={styles.mainMenu}/>;
    }
  }

  renderBreadcrumb(group) {
    const trail = [{ path: '/users/' + this.props.user + '/settings', label: 'Settings'}]
    switch(group) {
      case 'entities':
        trail.push({ path: '/users/' + this.props.user + '/settings/entities', label: 'Organisations'})
        break
      case 'venues':
        trail.push({ path: '/users/' + this.props.user + '/settings/venues', label: 'Venues'})
        break
      case 'events':
        trail.push({ path: '/users/' + this.props.user + '/settings/events', label: 'Events'})
        break
      default:

    }
    return <Breadcrumb trail={trail} className="mt-2 mb-2" />
  }

  render() {

    return (
      <React.Fragment>
        <Container className="bg-white settings-nav--main">
          <Menu />
        </Container>
        <Container className="bg-white">
          <Row>
              <Col id="page-content-wrapper">
                  {this.renderBreadcrumb(this.props.group)}
                  {this.renderContent(this.props.group)}
              </Col>
          </Row>
        </Container>
        <LoginModal
          />
        <Footer />
      </React.Fragment>
    );

  }


}

SettingsWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state

  return {
    group: ownProps.match && ownProps.match.params ? ownProps.match.params.group : null,
    authenticated: user && user.status && user.status === 'fetched',
    user: user && user.id ? user.id : null
  }
}

export default connect(mapStateToProps)(SettingsWindow)