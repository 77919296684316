import React from 'react';
import $ from 'jquery'
import Menu from '../navigation/Menu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Footer from '../navigation/Footer'
import Header from './Header'
import List from './List'
import GiftList from './GiftList'
import Accordion from 'react-bootstrap/Accordion'
import AccordionToggle from '../form/AccordionToggle'
import Card from 'react-bootstrap/Card'
import {fetchEvent} from '../../redux/event/actions'
import {fetchStreamEvent} from '../../redux/streamevent/actions'
import {openAccordion} from '../../redux/ui/actions'
import Total from './Total'
import LoginButton from '../auth/LoginButton'
import AddressSelector from '../user/AddressSelector'
import { getTenantCart, getCartShippingOptions, getCurrentUser } from '../../redux/state'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { setCartAddressAndSave, fetchStripeIntent, setCartMailingOptInAndSave } from '../../redux/shopping/actions'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import StripeCheckoutForm from './StripeCheckoutForm'
import Terms from './Terms'
import {trackPageView, initGA} from '../tracking/Google'
import { trackEvent, initFBPixel } from '../tracking/Facebook'
import ShippingSelector from '../shipping/Selector'
import OfferCodeForm from './OfferCodeForm'

class PaymentWindow extends React.Component {

  constructor(props){
    super(props)

    const state = {
      validated: false
    }

    this.state = state

    this.getRegistration = this.getRegistration.bind(this)
    this.getAddressDetails = this.getAddressDetails.bind(this)
    this.getShippingSelector = this.getShippingSelector.bind(this)
    this.selectAddress = this.selectAddress.bind(this)
    this.getGiftList = this.getGiftList.bind(this)
    this.getMailingListOptIn = this.getMailingListOptIn.bind(this)
  }

  componentDidMount() {

    const { dispatch } = this.props
    dispatch(fetchEntity(this.props.id))
      .then(() => {
        document.title = 'Checkout - ' + this.props.entity.name
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(setCurrentTenant('entities', this.props.entity.id))
        const {entity, user, cart} = this.props
        if(entity && entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id){
          initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)
          const fbData = cart.items.reduce((agg, current) => {
            agg.net += current.offering.net*current.quantity
            agg.vat += current.offering.vat*current.quantity
            agg.currency = current.offering.currency
            return agg
          }, {net:0, vat: 0, currency: 'GBP'})
          trackEvent('InitiateCheckout', fbData)
        }
        return dispatch(fetchStripeIntent(this.props.entity.id))
      })
      .catch(err => {
        console.log(err.message)
      })

    if(this.props.items){
      this.props.items.map(item => {
        switch(item.offering.resource.type){
          case 'events':
            dispatch(fetchEvent(item.offering.resource.id))
            break;
          case 'streamevents':
            dispatch(fetchStreamEvent(item.offering.resource.id))
            break;
          default:
        }
        return item
      })
    }
  }

  getSection(section, title, content){

    if(!content){
      return null
    }

    if(Array.isArray(content)){
      for(let i=0; i<content.length; i++){
        if(!content[i]){
          return null
        }
      }
    }

    const { index } = section

    const eventKey = String(index)

    const { dispatch } = this.props

    const onClick = () => {
      dispatch(openAccordion('PaymentWindow', Number(eventKey)))
    }

    section.index++

    return <Card className="accordion__content">
      <AccordionToggle className="text-secondary" variant="link" eventKey={eventKey} onClick={onClick}>
      {index+1}. {title}
      </AccordionToggle>
      <Accordion.Collapse eventKey={eventKey} >
        <Card.Body>
          {content}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  }

  handleNextClick(e){
    let index = $(e.target).parents('.card.accordion__content').first().index()
    index = index + 1 > $(e.target).parents('.card.accordion__content').first().siblings().length ? 0 : index + 1
    $('.card-header', $('.card.accordion__content', $(e.target).parents('.accordion').first()).get(index)).trigger('click')
    $('input, select, textarea', $('.card.accordion__content', $(e.target).parents('.accordion').first()).get(index)).first().trigger('focus')
  }

  getNextButton(disabled=false){
    const variant = disabled ? 'secondary' : 'primary'
    return <div className="text-end">
      <Button name="action" value="next" variant={variant} type="button" size="sm" onClick={this.handleNextClick} className="ms-2" disabled={disabled} >
        Next
      </Button>
    </div>

  }

  getOfferCodeForm(){
    const { entity } = this.props
    return <OfferCodeForm entity={entity.id} id="PaymentOfferCode" className="mb-3" />
  }

  getTotals(){

    const { requiresShipping, shippingSelected, hasDiscount } = this.props

    const shipping = requiresShipping && shippingSelected ? <React.Fragment><Col xs={6} md={9} className="text-end border-bottom pb-2 mb-2">
        Shipping
      </Col>
      <Col xs={6} md={3} className="text-end border-bottom pb-2 mb-2">
        <Total type="shipping" />
      </Col></React.Fragment> : null

    const discount = hasDiscount ? <React.Fragment><Col xs={6} md={9} className="text-end border-bottom pb-2 mb-2">
        Discount applied
      </Col>
      <Col xs={6} md={3} className="text-end border-bottom pb-2 mb-2">
        <Total type="discount" />
      </Col></React.Fragment> : null


    return <Row>
      {discount}
     <Col xs={6} md={9} className="text-end border-bottom pb-2 mb-2">
        Net total
      </Col>
      <Col xs={6} md={3} className="text-end border-bottom pb-2 mb-2">
        <Total type="net" />
      </Col>
      <Col xs={6} md={9} className="text-end border-bottom pb-2 mb-2">
        VAT
      </Col>
      <Col xs={6} md={3} className="text-end border-bottom pb-2 mb-2">
        <Total type="vat" />
      </Col>
      { shipping }
      <Col xs={6} md={9} className="text-end border-bottom pb-2 mb-2">
        <strong>Total</strong>
      </Col>
      <Col xs={6} md={3} className="text-end border-bottom pb-2 mb-2">
        <strong><Total type="total" /></strong>
      </Col>
    </Row>
  }

  getRegistration(){
    if(this.props.authenticated){
      return null
    }

    return <h4>
      <LoginButton
       label="Login"
       inline={true}
       className="text-primary me-1"
       />
      or
      <LoginButton
       label="Register"
       inline={true}
       className="text-primary ms-1"
       hash="RegistrationForm"
       />
     </h4>

  }

  getAddressDetails(type='billing'){
    const { address, addresses } = this.props
    if(!this.props.authenticated){
      return null
    }
    const value = address && address[type] ? address[type] : (addresses && addresses.length ? addresses[0].id : null)

    if((!address || !address[type]) && value){
      this.selectAddress(type + '_address', value)
    }
    return <AddressSelector name={ type + '_address' } label={ 'Choose a ' + type + ' address'} value={ value } handleChange={this.selectAddress} />
  }

  selectAddress(key, value){
    const { dispatch, entity } = this.props
    dispatch(setCartAddressAndSave(entity.id, key.replace(/_address$/, ''), value))
  }

  getMailingListOptIn(){
    const { dispatch, entity, cart } = this.props
    if(!entity || !entity._mailchimp || !entity._mailchimp || !entity._mailchimp.list ){
      return <div></div>
    }
    const label = entity._mailchimp.message || 'Please tick this box if you\'d like to receive e-mails relating to this event'
    const onChange = (event) => {
      dispatch(setCartMailingOptInAndSave(entity.id, event.target.checked))
    }
    return  <Form.Group className="" controlId="PaymentFormMailingOptIn">
        <Form.Check type="checkbox" label={label} onClick={onChange} checked={cart && cart.mailinglist && cart.mailinglist.optin}/>
      </Form.Group>
  }

  getShippingSelector(){
    const { shippingOptions, cart, shippingUnavailable, entity } = this.props
    if(shippingUnavailable){
      return <React.Fragment>
      <h5>Shipping unvailable</h5>
      <p>Arrgh! sorry, we don't have a shipping method available to handle your order. If you'd like to proceed, please contact us directly.</p>
      <p>{entity.name}: <a href={ 'mailto:' + entity.email }>{ entity.email }</a></p>
      </React.Fragment>
    }
    if(!shippingOptions || shippingOptions.length <= 1){
      return null
    }
    return <ShippingSelector cart={cart.id} />
  }

  getStripeForm(){

    const { publicKey, address, entity, terms, requiresShipping, shippingSelected, shippingUnavailable, dispatch, user } = this.props

    if(!publicKey || !address || !address.billing || !user){
      return null
    }

    if(requiresShipping && (!shippingSelected || shippingUnavailable)){
      return null
    }


    const promise = this.state.stripePromise ? this.state.stripePromise : loadStripe(publicKey)

    if(!this.state.stripePromise){
      this.setState({
        stripePromise: promise
      })
    }

    const hasTerms = (entity._ecommerce && entity._ecommerce.terms && entity._ecommerce.terms.content)
    const disabled = hasTerms && !terms
    const onSuccess = () => { dispatch(openAccordion('PaymentWindow', null)) }

    return <React.Fragment>
      <Elements stripe={promise}>
        <ElementsConsumer>
          {({elements, stripe}) => (
            <StripeCheckoutForm elements={elements} stripe={stripe} disabled={disabled} onSuccess={onSuccess} />
          )}
        </ElementsConsumer>
      </Elements>
      <Terms id={entity.id} />
    </React.Fragment>
  }

  getGiftList(){
    const { giftable } = this.props
    if(!giftable || !giftable.length){
      return null
    }
    return <GiftList />
  }

  render() {

    const { publicKey, address, requiresShipping, shippingSelected, shippingUnavailable, accordionSection } = this.props

    const section = { index: 0}

    const content = publicKey ?
      <Accordion defaultActiveKey={String(Number(accordionSection))}>

        {this.getSection(section, 'Your cart',
          [<List />,
           this.getTotals(),
           this.getNextButton()
          ]
        )}

        {this.getSection(section, 'Sharing',
          [this.getGiftList(),
           this.getNextButton()
          ])}

        {this.getSection(section, 'Register',
          [this.getRegistration()])}

        {this.getSection(section, 'Your ' + (requiresShipping ? 'billing ' : '') + 'address',
          [this.getAddressDetails('billing'),
           this.getNextButton(!(address && address.billing))
          ])}

        {requiresShipping ? this.getSection(section, 'Your shipping address',
          [this.getAddressDetails('shipping'),
           this.getNextButton(!(address && address.shipping))
          ]) : null}

        {requiresShipping ? this.getSection(section, 'Shipping',
          [this.getShippingSelector(),
           this.getNextButton(!shippingSelected || shippingUnavailable)
          ]) : null}

        {this.getSection(section, 'Payment details',
          [
           this.getOfferCodeForm(),
           this.getTotals(),
           this.getStripeForm(),
           this.getMailingListOptIn(),
          ])}

      </Accordion>
      : 'Loading . . .'

    return (
      <React.Fragment>
        <Container className="bg-white payment-window">
          <Menu />
            <div className="payment-container">
              <Row>
                <Col className="pt-2 pb-2">
                  <Header
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form ref={el => this.el = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="PaymentForm" className="bg-white py-1 pb-3">
                    {content}
                  </Form>
                </Col>
              </Row>
            </div>
        </Container>
        <Footer />
      </React.Fragment>
    );

  }

}

PaymentWindow.propTypes = {
  publicKey: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  requiresShipping: PropTypes.bool.isRequired,
  shippingOptions: PropTypes.array,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  entity: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  giftable: PropTypes.array
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const cart = getTenantCart(state)

  let { users, user, entities: {[id]: entity}, ui } = state

  if(entity && id === entity.alias){
    ({ entities: { [entity.id] : entity } } = state)
  }

  let quantity = 0
  let total = 0
  const giftable =[]

  if(cart && cart.items){
    cart.items.map(item => {
      quantity += item.quantity
      total += item.quantity * (item.offering.vat ? (100+item.offering.vat)*item.offering.net/100 : item.offering.net)
      if(item.offering.gifting && item.offering.gifting.enabled){
        giftable.push(item)
      }
      return item
    })
  }

  const publicKey = entity && entity._ecommerce && entity._ecommerce.stripe && entity._ecommerce.stripe.key ? entity._ecommerce.stripe.key.public : null

  const requiresShipping = cart && cart.items ? cart.items.reduce((agg, item) => {
    return agg || ((item.offering.resource.type === 'products'))
  }, false) : false

  return {
    publicKey,
    cart,
    items: cart ? cart.items : null,
    hasDiscount: cart && cart.totals && cart.totals.discount,
    requiresShipping,
    user: getCurrentUser(state),
    shippingSelected: cart && cart.shipping && (cart.shipping.id || cart.shipping.exempt),
    shippingOptions: cart && requiresShipping ? getCartShippingOptions(state, cart.id): null,
    shippingUnavailable: cart && cart.shipping && cart.shipping.unavailable,
    quantity,
    total,
    giftable,
    entity,
    addresses: users && user && users[user.id] && users[user.id].address ? users[user.id].address : null,
    address: cart ? cart.address : null,
    authenticated: user && user.status && user.status === 'fetched',
    terms: cart && cart.terms && cart.terms.accepted,
    accordionSection: ui && ui.accordion && ui.accordion.PaymentWindow ? ui.accordion.PaymentWindow : null
  }
}


export default connect(mapStateToProps)(PaymentWindow)