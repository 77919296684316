import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ListItem from './ListItem'

class List extends React.Component {

  render() {

    if(!this.props.data || !this.props.data.length){
      return <p>You have no orders.</p>
    }

    return <div className="order order__list">
      <p>Thank for your order{this.props.data.length > 1 ? 's' : ''}. Please find the details below. Once an order is marked 'dispatched', you will have immediate access to the event which you can access by clicking on the link in the order.</p>
      {this.props.data.map(order => {
        return <ListItem id={order.id} className="mb-3 bg-light" />
      })}
    </div>
  }

}

List.propTypes = {
  orders: PropTypes.array.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { orders } = state

  const data = orders ? Object.keys(orders).reduce((arr, item) => {
    arr.push(orders[item])
    return arr
  }, []).sort((a, b) => {
    return a.created > b.created  ? -1 : 1
  }) : null

  return {
    data
  }
}

export default connect(mapStateToProps)(List)