import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getTenantCart } from '../../redux/state'

class Total extends React.Component {

  render() {

    return <React.Fragment>£{this.props.total.toFixed(2)}</React.Fragment>
  }

}

Total.propTypes = {
  total: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const cart = getTenantCart(state)

  const shipping = cart && cart.shipping && cart.shipping.price && !isNaN(cart.shipping.price.value) ? cart.shipping.price.value : 0

  if(ownProps.type === 'shipping'){
    return {
      total: shipping
    }
  }

  if(cart && cart.totals && cart.totals[ownProps.type]){
    return {
      total: cart.totals[ownProps.type]
    }
  }

  let totals = []

  if(cart && cart.items){
    totals = cart.items.map(item => {
      //const gift = item.gifting && item.gifting.recipients  ? item.gifting.recipients.length * item.offering.net * (100-item.offering.gifting.reduction)/100 : 0
      const gift = 0
      const net = gift + item.quantity * item.offering.net
      const vat = item.offering.vat ? net * item.offering.vat/100 : 0
      const combined = net + vat
      switch(ownProps.type){
        case 'gift':
          return gift
        case 'vat':
          return vat
        case 'net':
          return net
        default:
          return combined
      }
    })
  }

  return {
    total : totals.reduce((acc, val) => acc+val, 0) + (['gift', 'vat', 'net', 'discount'].includes(ownProps.id) ? 0 : shipping)
  }
}

export default connect(mapStateToProps)(Total)