import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { faImage, faInfoCircle, faBuilding, faThList, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import ImageSelector from '../form/ImageSelector'
import ImagesSelector from '../form/ImagesSelector'
import CheckboxGroup from '../form/CheckboxGroup'
import { connect } from 'react-redux'
import { postVenue, patchVenue, fetchVenue, deleteVenue } from '../../redux/venue/actions'
import PropTypes from 'prop-types'
import ConfirmationModal from '../form/ConfirmationModal'
import Accordion from 'react-bootstrap/Accordion'
import Section from '../form/Section'
import { isVenueAdmin } from '../../redux/venue/state'
import AddressFields from '../form/AddressFields'
import { postLocation } from '../../redux/location/actions'
import { withRouter } from 'react-router'
import VenueSelector from './VenueSelector'
import EntitySelector from '../entity/EntitySelector'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        name: '',
        alias: '',
        parent: '',
        entity: {},
        image: '',
        details: {
          url: '',
          text: ''
        },
        description: '',
        tags: []
      },
      validated: false,
      aliasFeedback:'Please enter the venue\'s alias.',
      confirmation: {
        visible: false
      }
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    if(this.props.venue){
      state.data.parent = this.props.venue
    }

    if(this.props.entity){
      state.data.entity.id = this.props.entity
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.validateAlias = this.validateAlias.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id && this.props.id !== 'new'){
      dispatch(fetchVenue(this.props.id, true))
    }
  }

  handleInputChange(event) {
    let name = event.target.name
    let value = event.target.value
    if(event.target.type === 'checkbox'){
      value = Boolean(event.target.checked)
    }
    if(name === 'alias'){
      value = value.replace(/\W+/g, '-').toLowerCase()
    }
    if(name==='entity' && value && this.props.entities[value]){
      value = { id: value, name: this.props.entities[value].name, alias: this.props.entities[value].alias}
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if(name==='name' && !this.props.data){
      data.alias = value.replace(/\W+/g, '-').toLowerCase()
    }
    this.setState({
      data: data,
      validated: false,
      feedbackMessage: ''
    })
    if(data.alias){
      this.validateAlias(data.alias)
        .then(validated => {
          this.setState({
            validated: !validated,
            aliasFeedback: validated ? 'Please enter the venue\'s alias.' : 'This alias is already in use.'
          })
          $('#VenueForm input[name="alias"]').get(0).setCustomValidity(validated ? '' : 'Invalid field')
        })
    }
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  validateAlias(alias){
    if(this.props.data && this.props.data.alias && this.props.data.alias === alias){
      return Promise.resolve(true)
    }
    return new Promise((resolve, reject) => {
      const url = window.endpoint.venues + '/venues/' + alias + '/existence'
      fetch(url)
        .then(response => {
          if(response.ok){
            throw new Error('Alias exists')
          }
          resolve(true)
        })
        .catch(error => {
          resolve(false)
        })
    })
  }

  handleSubmit(event){
    event.preventDefault()
    event.stopPropagation()
    this.saveData(event.currentTarget, event.nativeEvent.submitter.value)
  }

  saveData(form, action){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      let invalid = form.querySelectorAll(':invalid');
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const data = {...this.state.data}
    if(this.props.entities && Object.keys(this.props.entities).length && (!data.entity || !data.entity.id)){
      const entityID = Object.keys(this.props.entities)[0]
      const entity = this.props.entities[entityID]
      data.entity = {
        id: entityID,
        alias: entity.alias,
        name: entity.name
      }
    }
    data.active = 1
    const { dispatch } = this.props

    // Submit address
    Promise.resolve()
    .then(result => {
      if(data.location && data.location.address && data.location.address instanceof Object && data.location.address.postcode){
        return dispatch(postLocation({ address: data.location.address }))
      }
      return null
    })
    .then(result => {
      if(result){
        data.location.address = result.data.id
      }else if(data.location && data.location.address && data.location.address instanceof Object){
        data.location.address = ''
      }
      return true
    })
    .then(result => {
      if(this.props.data && data.action !== 'new'){
        return dispatch(patchVenue(data))
      }
      delete data.id
      delete data.action
      return dispatch(postVenue(data))
    })
    .then(data => {
      if(/\bclose\b/.test(action)){
        const { history, match } = this.props
        history.push(`/users/${match.params.id}/settings/venues`)
        //return dispatch(showModal('venues',this.props.id, false))
      }
      return data
    })
    .then(data => this.handleSaveSuccess(data))
    .catch(err => console.log(err.message))
  }

  handleSaveSuccess(data){
    this.setState({
      validated: false,
      feedbackMessage: 'Venue saved successfully'
    })
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  handleDelete(){
    if(this.props.data && this.props.data.id){
      const { dispatch } = this.props
      dispatch(deleteVenue(this.props.data.id))
        .then(result => {
          this.props.handleClose()
        })
    }
    this.hideConfirmation()
  }

  hideConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: false}}
    })
  }

  showConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: true}}
    })
  }

  render() {

    const { data } = this.props

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    const deleteButton = this.props.data ?
      <Button variant="danger" type="button" className="me-2" onClick={this.showConfirmation}>
        Delete
      </Button>
      : null

    const orgSelect = (this.props.entities && Object.keys(this.props.entities).length > 1) ?
      <Form.Group controlId="VenueOrganisationInput">
        <Form.Label className="mt-2 mb-0">Organisation</Form.Label>
        <Form.Control as="select" name="entity" onChange={this.handleInputChange} value={this.getValue('entity.id')}>
          {Object.keys(this.props.entities).map(key => (
            <option key={key} value={key}>{this.props.entities[key].name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      : null

    const headerClass = 'text-secondary'

    return (
      <Form ref={el => this.el = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} id="VenueForm" className="bg-white py-1 px-2 pb-3">
        <Accordion defaultActiveKey="0">

          <Section title="Venue Settings" eventKey="0" className={headerClass} icon={faBuilding}>
            <Form.Group controlId="VenueNameInput">
              <Form.Label className="mt-2 mb-0">Name</Form.Label>
              <Form.Control name="name" onChange={this.handleInputChange} placeholder="The venue's name" value={this.getValue('name')} required />
              <Form.Control.Feedback type="invalid">
                Please enter the venue's name
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="VenueAliasInput">
              <Form.Label className="mt-2 mb-0">Alias</Form.Label>
              <Form.Control name="alias" onChange={this.handleInputChange} placeholder="The venue's alias" value={this.getValue('alias')} required />
              <Form.Control.Feedback type="invalid">
                { this.state.aliasFeedback }
              </Form.Control.Feedback>
            </Form.Group>

            <VenueSelector
              id="VenueParentInput"
              name="parent"
              venue={this.props.data ? this.props.data.id : null}
              onChange={this.handleInputChange}
              value={this.getValue('parent')}
              label="Parent venue"
              />

            <EntitySelector
              id="VenueOrganisationInput"
              name="entity_id"
              onChange={this.handleInputChange}
              value={this.getValue('entity.id')}
              label="Organisation"
            />

          </Section>

          <Section title="Display Settings" eventKey="1" className={headerClass} icon={faImage}>

            <Form.Group controlId="VenueImageInput">
              <Form.Label className="mt-2 mb-0">Main image</Form.Label>
                <div className="input-group align-items-start" id="VenueLogoInput" data-target-input="nearest">
                  <ImageSelector
                    image={this.getValue('image')}
                    name="image"
                    type="image"
                    handleChange={this.setValue}
                    />
                </div>
            </Form.Group>

            <Form.Group controlId="VenueDescriptionInput">
              <Form.Label className="mt-2 mb-0">Description</Form.Label>
              <Form.Control name="description" as="textarea" rows="3" onChange={this.handleInputChange} placeholder="The venue's description" value={this.getValue('description')} />
            </Form.Group>

          </Section>

          <Section title="Details Settings" eventKey="2" className={headerClass} icon={faInfoCircle}>

            <Form.Group controlId="VenueDetailsURLInput">
              <Form.Label className="mt-2 mb-0">URL</Form.Label>
              <Form.Control name="details_url" onChange={this.handleInputChange} placeholder="A link to further details or tickets for the venue" value={this.getValue('details.url')} type="url" />
              <Form.Control.Feedback type="invalid">
                This must be a full URL, including http:// or https:// at the beginning
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="VenueDetailsTextInput">
              <Form.Label className="mt-2 mb-0">Label</Form.Label>
              <Form.Control name="details_text" onChange={this.handleInputChange} placeholder="The label for the 'more details' button - default 'More details'" value={this.getValue('details.text')} />
            </Form.Group>

          </Section>

          <Section title="Location Settings" eventKey="3" className={headerClass} icon={faMapMarker}>

            <AddressFields handleChange={ (data) => this.setValue('location_address', data) } id={data && data.location ? data.location.address : null} />

            <Form.Group controlId="VenueLatitude">
              <Form.Label className="mt-2 mb-0">Latitude</Form.Label>
              <Form.Control name="location_latitude" onChange={this.handleInputChange} placeholder="The location's latitude" value={this.getValue('location.latitude')} type="number" />
              <Form.Control.Feedback type="invalid">
                Please supply a valid number
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="VenueLongitude">
              <Form.Label className="mt-2 mb-0">Longitude</Form.Label>
              <Form.Control name="location_longitude" onChange={this.handleInputChange} placeholder="The location's longitude" value={this.getValue('location.longitude')} type="number" />
              <Form.Control.Feedback type="invalid">
                Please supply a valid number
              </Form.Control.Feedback>
            </Form.Group>

          </Section>

          <Section title="Feed Settings" eventKey="4" className={headerClass} icon={faThList}>

            <Form.Group controlId="VenueFeedIntroductionInput">
              <Form.Label className="mt-2 mb-0">Introduction</Form.Label>
              <Form.Control name="feed_introduction" as="textarea" rows="3" onChange={this.handleInputChange} placeholder="Introduction for the venue's feed" value={this.getValue('feed.introduction')} />
            </Form.Group>

            <Form.Group controlId="VenueImagesInput">
              <Form.Label className="mt-2 mb-0">Banner images</Form.Label>
                <div id="ProductImagesInput" data-target-input="nearest">
                  <ImagesSelector
                    images={this.getValue('images')}
                    name="images"
                    handleChange={this.setValue}
                    container={this.props.modalBody}
                    />
                </div>
            </Form.Group>

            <Form.Group controlId="VenueFeedContentInput">
              <Form.Label className="mt-2 mb-0">Content</Form.Label>
              <CheckboxGroup
                options={[
                  { value: 'venues', label: 'Sub-venues'},
                  { value: 'events', label: 'Events'}
                ]
                }
                name="feed_content"
                value={this.getValue('feed.content') || ['venues', 'events']}
                onChange={this.setValue}
                />
            </Form.Group>

            <Form.Group controlId="VenueFeedGroupingInput">
              <Form.Label className="mt-2 mb-0">Grouping</Form.Label>
              <Form.Control as="select" name="feed_grouping" onChange={this.handleInputChange} value={this.getValue('feed.grouping')}>
                <option value="">None</option>
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="VenueFeedSortInput">
              <Form.Label className="mt-2 mb-0">Feed order</Form.Label>
              <Form.Control as="select" name="feed_sort" onChange={this.handleInputChange} value={this.getValue('feed.sort')}>
                <option value="datetime">Venue times</option>
                <option value="-created">Latest post</option>
                <option value="title">Alphabetical</option>
                <option value="-rating">Rating</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="VenueFeedSplitInput">
                <Form.Check type="checkbox" name="feed_split" onChange={this.handleInputChange} label="Hide past venues" checked={this.getValue('feed.split')}/>
                <Form.Text className="text-muted">
                  If ticked, and the feed order is set to 'venue times', venues that have finished will be hidden by default
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="VenueFeedCssInput">
              <Form.Label className="mt-2 mb-0">Styles</Form.Label>
              <Form.Control name="feed_css" as="textarea" rows="3" onChange={this.handleInputChange} placeholder="Custom CSS for the venue's feed" value={this.getValue('feed.css')} />
            </Form.Group>

          </Section>

        </Accordion>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="mt-3">
          { deleteButton }
          <Button name="action" value="save-close" variant="primary" type="submit" className="me-2">
            Save &amp; Close
          </Button>
        </div>
        <ConfirmationModal
          title="Delete venue"
          body="Deleting a venue can't be undone. Are you sure about this?"
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.handleDelete}
          visible={this.state.confirmation.visible}
        />
      </Form>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object,
  feed: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  let { venues:existing, user, feeds } = state

  const data = existing ? existing[ownProps.id] : null

  let entities = {}

  if(user && user.entities && Array.isArray(user.entities)){
    user.entities.map((id, index) => {
      entities[id] = state.entities[id]
      return id
    })
  }

  return {
    data,
    owner: isVenueAdmin(state, ownProps.id),
    entities,
    feed : feeds['venue-' + ownProps.id] ? 'venue-' + ownProps.id : null
  }
}

export default connect(mapStateToProps)(withRouter(EditForm))