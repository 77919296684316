import AccordionToggle from './AccordionToggle'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default props => {

  const { className, eventKey, icon, children, title } = props

  return <Card>
    <AccordionToggle className={className} variant="link" eventKey={`${eventKey}`}>
      { icon  ?
        <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={icon} /></span>
      :
      null }
      { title }
    </AccordionToggle>
    <Accordion.Collapse eventKey={`${eventKey}`}>
      <Card.Body>

        { children }

        <div className="text-end mt-2">
          <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
            Save
          </Button>
        </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
}