import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getEntityCart, getEntity } from '../../redux/state'
import TextBlock from '../display/TextBlock'
import Modal from '../display/Modal'
import ModalButton from '../display/ModalButton'
import { acceptTerms } from '../../redux/shopping/actions'
import Form from 'react-bootstrap/Form';

class Terms extends React.Component{

  constructor(props){
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event){
    const { dispatch, entity } = this.props
    dispatch(acceptTerms(entity.id, event.target.checked))
  }

  render(){

    const { entity, className, terms, accepted } = this.props

    if(!terms){
      return null
    }

    const modalContent = terms.format && terms.format === 'html' ?
      <div dangerouslySetInnerHTML={{__html : terms.content}} />
      :
      <TextBlock text={terms.content} />

    const label = <span>
      I accept the <ModalButton
      resource="terms"
      id={entity.id}
      mode="text"
      className="text-primary border-bottom"
      label="terms &amp; conditions" />
    </span>

    return <div className={className}>
      <Form.Group controlId="TermsCheck">
        <Form.Check type="checkbox" checked={accepted} onChange={this.handleChange} label={label} />
      </Form.Group>
      <Modal
        resource="terms"
        id={entity.id}
        title="Terms &amp; Conditions"
        content={modalContent}
        />
    </div>
  }
}

Terms.propTypes = {
  entity: PropTypes.object.isRequired,
  terms: PropTypes.object.isRequired,
  accepted: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const entity = getEntity(state, ownProps.id)

  const cart = getEntityCart(state, ownProps.id)

  return {
    entity,
    terms: entity && entity._ecommerce && entity._ecommerce.terms && entity._ecommerce.terms.content ? entity._ecommerce.terms : null,
    accepted: cart && cart.terms && cart.terms.accepted
  }
}

export default connect(mapStateToProps)(Terms)