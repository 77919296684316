import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { CountryDropdown } from 'react-country-region-selector'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    this.default =       {
        name: '',
        country: 'GB',
        weight: {
          min: null,
          max: null
        },
        size: {
          min: null,
          max: null
        },
        factor: 5000,
        rounding: null,
        duration: 1,
        price: {
          value: null,
          currency: 'GBP'
        }
      }


    var state = {
      data: this.default,
      validated: false,
      changed: false,
      feedbackMessage:'',
      serverError: '',
      confirmation: {
        visible: false
      }
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    this.state = state

    this.getDimensionField = this.getDimensionField.bind(this)
    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.saveData = this.saveData.bind(this)
  }

  handleInputChange(event) {
    let name, value
    name = event.target.name
    value = event.target.value
    if(event.target.type === 'checkbox'){
      value = Boolean(event.target.checked)
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    if(value !== '' && !isNaN(parseFloat(value))){
      value = Number(value)
    }
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if(name==='save' && value === 'duplicate'){
      delete data.id
    }
    this.setState({
      data: data,
      validated: false,
      changed: true,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] === 0 ? '0' : pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleCancel(event){
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      data: this.default
    })
    if(this.props.handleCancel){
      this.props.handleCancel()
    }
  }


  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    $('#ShippingNameInput', $(this.el)).attr('required', 'required')
    $('#ShippingNetInput', $(this.el)).attr('required', 'required')
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      return
    }
    const data = Object.assign({}, this.state.data)
    if(this.props.handleSave){
      this.props.handleSave(data)
        .then(result => {
          $('#ShippingNameInput', $(this.el)).removeAttr('required', 'required')
          $('#ShippingNetInput', $(this.el)).removeAttr('required', 'required')
          this.setState({
            validated: false,
            changed: false,
            data: this.default,
            feedbackMessage: ''
          })
        })
        .catch(err => {
          this.setState({
            serverError: err.message
          })
        })
    }
  }

  getDimensionField(name, unit='cm', notes=null){
    const nameLC = name.toLowerCase()

    notes = notes ? <Form.Text className="text-muted">
      { notes}
    </Form.Text>
    : null

    return <Form.Group controlId={'Shipping' + name + 'Input'}>
      <h6 className="mt-3 mb-0">{name}</h6>
      <Row>
        <Col>
          <Form.Label className="mt-2 mb-0">Minimum</Form.Label>
          <Form.Control name={nameLC + '_min'} onChange={this.handleInputChange} placeholder={'The minimum ' + nameLC + ' in ' + unit} value={this.getValue(nameLC + '.min')} type="number" />
          <Form.Control.Feedback type="invalid">
            Please supply a valid { nameLC }
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label className="mt-2 mb-0">Maximum</Form.Label>
          <Form.Control name={nameLC + '_max'} onChange={this.handleInputChange} placeholder={'The maximum ' + nameLC + ' in ' + unit} value={this.getValue(nameLC + '.max')} type="number" />
          <Form.Control.Feedback type="invalid">
            Please supply a valid { nameLC }
          </Form.Control.Feedback>
        </Col>
      </Row>
      { notes }
    </Form.Group>

  }

  render() {

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    const editMode = Boolean(this.props.data)

    return (
      <Form ref={el => this.el = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} className={this.props.className}>

        <Form.Group controlId="ShippingNameInput">
          <Form.Label className="mt-2 mb-0">Name</Form.Label>
          <Form.Control name="name" onChange={this.handleInputChange} placeholder="e.g. 'Royal Mail First Class Letter'" value={this.getValue('name')} />
          <Form.Control.Feedback type="invalid">
            Please the shipping method's name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="ShippingCountryInput">
          <Form.Label className="mt-2 mb-0">Country</Form.Label>
          <CountryDropdown
            value={this.getValue('country')}
            valueType="short"
            onChange={ (val) => this.setValue('country', val) }
            className="form-control"
            />
        </Form.Group>

        {this.getDimensionField('Weight', 'kg')}

        <Form.Group controlId="ShippingRoundingInput">
          <Form.Label className="mt-2 mb-0">Rounding</Form.Label>
          <Form.Control name="rounding" onChange={this.handleInputChange} placeholder="Rounding in kilos" value={this.getValue('rounding')} type="number" />
          <Form.Text className="text-muted">
            Unit weight in kilos to which shipping weights should be rounded up. For banded prices, set this value to the same as the maximum weight.
          </Form.Text>
        </Form.Group>

        {this.getDimensionField('Length', 'cm', 'The longest dimension of the package')}
        {this.getDimensionField('Width', 'cm', 'The second longest dimension of the package')}
        {this.getDimensionField('Height', 'cm', 'The shortest dimension of the package')}

        <Form.Group controlId="ShippingFactorInput">
          <Form.Label className="mt-2 mb-0">Dimensional weight factor</Form.Label>
          <Form.Control name="factor" onChange={this.handleInputChange} placeholder="In cm3/kg" value={this.getValue('factor')} type="number" />
          <Form.Text className="text-muted">
            The dimensional weight factor used by this shipping method <a href="https://en.wikipedia.org/wiki/Dimensional_weight" className="inline-link" target="_blank">https://en.wikipedia.org/wiki/Dimensional_weight</a>
          </Form.Text>
         </Form.Group>

        <Form.Group controlId="ShippingPriceInput">
          <Row>
            <Col>
              <Form.Label className="mt-2 mb-0">Currency</Form.Label>
              <Form.Control as="select" name="price_currency" onChange={this.handleInputChange} value={this.getValue('price.currency')}>
                <option value="GBP">£ - British Pound</option>
                <option value="USD">$ - US Dollar</option>
                <option value="EUR">€ - Euro</option>
              </Form.Control>
            </Col>
            <Col>
              <Form.Label className="mt-2 mb-0">Price</Form.Label>
              <Form.Control name="price_value" onChange={this.handleInputChange} placeholder="Price per volumetric kilo" value={this.getValue('price.value')} type="number" />
              <Form.Control.Feedback type="invalid">
                Please supply a valid price
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group controlId="ShippingRoundingInput">
          <Form.Label className="mt-2 mb-0">Delivery</Form.Label>
          <Form.Control name="duration" onChange={this.handleInputChange} placeholder="Delivery (working days)" value={this.getValue('duration')} type="number" />
          <Form.Text className="text-muted">
            The number of working days delivery should take.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="ShippingActiveInput">
           <Form.Check type="checkbox" name="active" onChange={this.handleInputChange} label="Active" checked={this.getValue('active')}/>
        </Form.Group>

        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="text-end">
          <Button value="cancel" variant="secondary" className="me-2" size="sm" onClick={this.handleCancel}>
            { editMode ? 'Cancel' : 'Reset' }
          </Button>
          { editMode ? <Button value="duplicate" className="me-2" variant="dark" type="submit" name="save" size="sm" onClick={this.handleInputChange}>
            Duplicate
          </Button> : null }
          <Button value="save" variant="primary" type="submit" name="save" size="sm">
            { editMode ? 'Update' : 'Add' }
          </Button>
        </div>

      </Form>
    )
  }
}

export default EditForm