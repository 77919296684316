import React from 'react'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { deleteTokens } from '../../redux/token/actions'
import { hideModalsAndShow } from '../../redux/ui/actions'
import { setPostAuthDestination, deleteRefreshToken } from '../../redux/auth/actions'
import PropTypes from 'prop-types'
import requiredServices from '../../config/services'

class LoginButton extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      window: null
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getBootstrapButton = this.getBootstrapButton.bind(this);
    this.getInlineButton = this.getInlineButton.bind(this);
    this.useModal = this.useModal.bind(this);
    this.useWindow = this.useWindow.bind(this);
  }

  render() {
     if(this.props.mode === 'in' && this.props.authenticated){
       return null
     }
     if(this.props.mode === 'out' && !this.props.authenticated){
       return null
     }
     return this.props.inline ? this.getInlineButton() : this.getBootstrapButton()
  }

  getBootstrapButton(){
      const variant = this.props.variant || 'primary'
      const label = this.props.label || (this.props.authenticated ? 'Sign Out' : 'Sign In/Join')
      return (
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
          {label}
        </Button>
      );
  }

  getInlineButton(){
    const label = this.props.label || (this.props.authenticated ? 'Sign Out' : 'Sign In/Join')
    const className = this.props.className || 'inline-link'
    return <button title={label} onClick={this.handleClick} className={className} >
          {label}
        </button>
  }

  handleClick(e){
    e.preventDefault()
    e.stopPropagation()
    if(this.props.authenticated){
      return this.handleLogout(e)
    }
    if(window.endpoint.authUI && window.endpoint.authUI.match(/^https?:\/\//)){
      this.useWindow()
    }else{
      this.useModal()
    }
  }

  useModal(){
    const { dispatch, destination } = this.props
    dispatch(hideModalsAndShow('login', true))
    if(destination){
      dispatch(setPostAuthDestination(destination))
    }
  }

  useWindow(){
    const { dispatch, hash } = this.props
    let { destination } = this.props

    destination = destination ? window.location.origin + destination : window.location.href
    destination = destination.replace(/\?.*/, '')
    const url = window.endpoint.authUI + '?redirect=' + encodeURIComponent(destination) + ( hash ? '#' + hash : '')

    if(this.state.window){
      this.state.window.close()
      this.setState({
        window: null
      })
    }
    var w=390, h=660
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const authWindow = window.open(url, 'Auth',
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) authWindow.focus()

    this.setState({
      window: authWindow
    })

  }

  handleLogout(e){
    e.preventDefault();

    const { dispatch } = this.props
    dispatch(deleteRefreshToken())
    dispatch(deleteTokens(requiredServices, true))
  }

}

LoginButton.propTypes = {
  authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  const { tokens } = state

  for(const service of requiredServices){
    if(!tokens[service] || tokens[service].status !== 'fetched'){
      return {authenticated: false}
    }
  }

  return {
    authenticated: true
  }
}

export default connect(mapStateToProps)(LoginButton)