import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCurrentUser, getEntity, getTenantCart } from '../../redux/state'
import { handlePaymentSuccess } from '../../redux/shopping/actions'
import Button from 'react-bootstrap/Button'
import { withRouter } from 'react-router'
import { trackEvent, initFBPixel } from '../tracking/Facebook'

class StripeCheckoutForm extends React.Component{

  constructor(props){
    super(props)

    const state = {
      succeeded: false,
      processing: false,
      error: null,
      disabled: false
    }

    this.state = state

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event){
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    this.setState({
      disabled: event.empty,
      error: event.error ? event.error.message : ''
    })
  }

  handleSubmit(event){

    event.preventDefault()
    event.stopPropagation()

    const { dispatch, user, onSuccess, history, entity } = this.props

    this.setState({
      processing: true
    })

    this.props.stripe.confirmCardPayment(this.props.clientSecret, {
      payment_method: {
        card: this.props.elements.getElement(CardElement)
      }
    })
    .then(result => {
      if (result.error) {
        this.setState({
          error: `Payment failed ${result.error.message}`,
          processing: false
        })
      } else {
        this.setState({
          error: null,
          processing: false,
          succeeded: true
        })
        dispatch(handlePaymentSuccess(this.props.cart.entity.id))

        if(entity && entity.facebook && entity.facebook.pixel && entity.facebook.pixel.id){
          initFBPixel(entity.facebook.pixel.id, user && user.email ? user.email : null)
          const fbData = this.props.cart.items.reduce((agg, current) => {
            agg.net += current.offering.net*current.quantity
            agg.vat += current.offering.vat*current.quantity
            agg.currency = current.offering.currency
            return agg
          }, {net:0, vat: 0, currency: 'GBP'})
          trackEvent('Purchase', fbData)
        }

        if(onSuccess){
          onSuccess(this.props.cart.entity.id)
        }
        history.push('/users/' + user.id + '/orders')
      }
    })
    .catch(err => {
      this.setState({
        error: `Payment failed ${err.message}`,
        processing: false
      })
    })
  }

  render(){

    if(!this.props.cart){
      console.log('No cart')
      return null
    }

    const { processing, disabled, succeeded, error } = this.state

    const cardStyle = {
      style: {
        base: {
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#32325d'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    }

    const success = succeeded ? <p className="result-message">
        Payment succeeded, see the result in your
        <a
          href={`https://dashboard.stripe.com/test/payments`}
        >
          {" "}
          Stripe dashboard.
        </a> Refresh the page to pay again.
      </p>
      : null

    const disableButton = processing || disabled || succeeded || this.props.disabled
    const variant = disableButton ? 'secondary' : 'primary'

    return <form id="payment-form" onSubmit={this.handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={this.handleChange} />
      <p className="text-end mb-0 mt-2">
      <Button
        disabled={ disableButton }
        variant={ variant }
        type="submit"
        className="ms-2"
        onClick={this.handleSubmit}
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </Button>
      </p>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      { success }
    </form>
  }
}

StripeCheckoutForm.propTypes = {
  cart: PropTypes.object.isRequired,
  clientSecret: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const cart = getTenantCart(state)

  const clientSecret = cart && cart.intents && cart.intents.stripe ? cart.intents.stripe.clientSecret : null

  return {
    user: getCurrentUser(state),
    entity: cart ? getEntity(state, cart.entity.id) : null,
    cart,
    clientSecret
  }
}

export default connect(mapStateToProps)(withRouter(StripeCheckoutForm))