import React from 'react';
import $ from 'jquery'
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { isEntityOwner } from '../../redux/state'

class OrderDownloadButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    if(!this.props.authorised){
      return null
    }

    const variant = this.props.variant || 'primary';
    const label = 'Download sales report'

    if(this.props.mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={this.props.className}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant} title={label} onClick={this.handleClick} className={this.props.className} size={this.props.size}>
          <FontAwesomeIcon icon={faDownload} className="d-inline" /> <span className="d-none d-md-inline text-nowrap ms-2">{label}</span>
        </Button>
      </React.Fragment>
    )

  }

  handleClick(e){
    const { id } = this.props
    const mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': mime },
      credentials: 'include'
    }

    const auth = localStorage.getItem('shoppingApiToken')
    if(auth) requestOptions.headers['Authentication-Info'] =  auth

    const path = '/entities/' + id + '/orders'
    const url = window.endpoint.shopping + path
    fetch(url, requestOptions)
      .then(response => {
        if(!response.ok){
          throw new Error('Error receiving order data')
        }
        return response.blob()
      })
      .then(result => {
        const url = URL.createObjectURL(result)
        const a = $("<a style='display: none;'/>");
        a.attr("href", url);
        a.attr("download", 'ZarucchiOrders-' + (new Date()).toLocaleDateString('en-GB') + '.xlsx');
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
      .catch(err => {
        console.log(err.message)
      })
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    authorised: isEntityOwner(state, ownProps.id)
  }
}

export default connect(mapStateToProps)(OrderDownloadButton)