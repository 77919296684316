import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import EditForm from './EditForm'
import { useSelector, useDispatch } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import { eventIsLoaded } from '../../redux/event/state'

export default props => {

  const { id, entity, event } = props

  const dispatch = useDispatch()
  const [eventId, setEventId] = useState(id)

  const show = useSelector(state => {
    const { modals: { events: ui } } = state
    return  ui && ui[id] && ui[id].visible
  })

  const isLoaded = useSelector(state => eventIsLoaded(state, eventId))

  useEffect(() => {
    if(!show){
      setEventId(id)
    }
  }, [show])

  const title = id === 'new' ? 'Add an event' : 'Edit event'

  const handleClose = e => {
    dispatch(showModal('events',id, false))
  }

  const handleSaveSuccess = result => {
    if(result && result.data && result.data.id){
      setEventId(result.data.id)
    }
  }

  return (
    <Modal
        show={show}
        aria-labelledby="login-modal"
        centered
        onHide={handleClose}
        size="lg"
        backdrop="static"
        autoFocus={false}
        enforceFocus={false}
      >
      <Modal.Header closeButton>
        <Modal.Title id="login-modal">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditForm
          handleClose={handleClose}
          handleSaveSuccess={handleSaveSuccess}
          id={eventId}
          key={`${eventId}-${isLoaded}`}
          entity={entity}
          event={event}
        />
      </Modal.Body>
    </Modal>
    )
}