import { patch, get, getMany } from '../crud/actions'

/**
 * USER
 */
export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER'
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER'
export const RECEIVE_USER_RESOURCES = 'RECEIVE_USER_RESOURCES'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'

export const requestCurrentUser = (id) =>{
  return {
    type: REQUEST_CURRENT_USER,
    id
  }
}

export const receiveCurrentUser = (id) => {
  return {
    type: RECEIVE_CURRENT_USER,
    id
  }
}

export const receiveUserResources = (resource, data, role='user') => {
  return {
    type: RECEIVE_USER_RESOURCES,
    resource,
    data,
    role
  }
}

export const clearCurrentUser = (id) => {
  return {
    type: CLEAR_CURRENT_USER,
    id
  }
}

export const fetchCurrentUser = (id, reload=false) => {
  return (dispatch, getState) => {
    id = id || getState().user.id
    if(!id){
      return Promise.resolve(false)
    }
    return dispatch(get('users', id, { reload }))
      .then((result) => {
        dispatch(receiveCurrentUser(id))
        return result
      })
  }
}


export const fetchUser = (id, reload=false) => {
  return (dispatch, getState) => {
    return dispatch(get('users', id, { reload }))
  }
}

export const patchUser = (data) => {
  return (dispatch, getState) => {
    return dispatch(patch('users', data))
  }
}

export const fetchUsers = (ids) => {
  return (dispatch, getState) => {
    ids = Array.isArray(ids) ? ids.join(',') : ids
    return dispatch(getMany('users', { filter: {id: ids}}))
  }
}

/**
 * Platform roles
 */
export const REQUEST_PLATFORM_SUPPORT_ROLE = 'REQUEST_PLATFORM_SUPPORT_ROLE'
export const RECEIVE_PLATFORM_SUPPORT_ROLE = 'RECEIVE_PLATFORM_SUPPORT_ROLE'

export const requestPlatformSupportRole = () =>{
  return {
    type: REQUEST_PLATFORM_SUPPORT_ROLE
  }
}

export const receivePlatformSupportRole = () => {
  return {
    type: RECEIVE_PLATFORM_SUPPORT_ROLE
  }
}

export const fetchPlatformSupportRole = (reload=false) => {
  return (dispatch, getState) => {
    if(!reload
      && getState().user
      && getState().user.platform){
      return Promise.resolve(getState().user.platform.support)
    }
    dispatch(requestPlatformSupportRole())
    const opts = { path: '/users/' + getState().user.id + '/resources/platform/roles/support' }
    return dispatch(get('entities', '', opts))
      .then((result) => {
        if(result){
          dispatch(receivePlatformSupportRole())
        }
      })
  }
}