import React from 'react';
import { connect } from 'react-redux'
import Purchase from '../offering/Purchase'
import CheckoutButton from '../cart/CheckoutButton'
import { withRouter } from 'react-router'
import { showModal } from '../../redux/ui/actions'
import { getStreamEventPaymentEntity, getStreamEventOfferings } from '../../redux/state'

class TicketList extends React.Component {

  constructor(props){
    super(props)
    this.checkout = this.checkout.bind(this)
  }

  checkout(e){
    e.stopPropagation()
    e.preventDefault()
    const { dispatch, history, id, entity } = this.props
    dispatch(showModal('tickets-streamevents', id, false))
    history.push('/entities/' + (entity.alias || entity.id) + '/checkout')
  }

  render() {

   const {data} = this.props

   if(!data){
     return null
   }

   return (
    <div>
      {data.map((item, index) => {
        return <Purchase key={index} offering={item} className="mb-2" />
      })}
      <div className="text-end">
        <CheckoutButton className="mt-1 btn-block btn-md-inline" handleClick={this.checkout} />
      </div>
    </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => {

  const data = getStreamEventOfferings(state, ownProps.id)

  const entity = getStreamEventPaymentEntity(state, ownProps.id)

  return {
    entity,
    data
  }
}

export default connect(mapStateToProps)(withRouter(TicketList))