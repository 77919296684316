import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFilm, faImage, faVideo, faCalendarDay, faBullhorn, faTicketAlt, faCartPlus } from '@fortawesome/free-solid-svg-icons'
import TagSelector from '../form/TagSelector'
import ImageSelector from '../form/ImageSelector'
import { connect } from 'react-redux'
import { postStreamEvent, patchStreamEvent, fetchStreamEvent, deleteStreamEvent } from '../../redux/streamevent/actions'
import { showModal } from '../../redux/ui/actions'
import PropTypes from 'prop-types'
import ConfirmationModal from '../form/ConfirmationModal'
import Accordion from 'react-bootstrap/Accordion'
import AccordionToggle from '../form/AccordionToggle'
import Card from 'react-bootstrap/Card'
import OfferingList from '../offering/List'
import { setFeedScrollPosition } from '../../redux/feed/actions'
import { isEventOwner, getEvent, getStreamEvent, getEventEntity } from '../../redux/state'
import RelatedItems from './RelatedItems'

class EditForm extends React.Component {
  constructor(props) {
    super(props)

    var state = {
      data: {
        stream: {
          type: 'youtube',
          id: ''
        },
        entity: {},
        event: {},
        title: '',
        subtitle: '',
        logo: '',
        details: {},
        datetime: null,
        duration: '',
        visibility: 'private',
        access: 'inherit',
        image: '',
        paypal: {
          donate:{
            id: '',
            text: ''
          }
        },
        merchandise: {
          content: 'url'
        },
        related: [],
        credits: {
          content: 'text',
          title: '',
          body: ''
        },
        tags: []
      },
      validated: false,
      changed: false,
      feedbackMessage:'',
      confirmation: {
        visible: false
      }
    }

    if(this.props.data){
      state = $.extend(true, {}, state, {data: this.props.data})
    }

    if(this.props.event){
      state.data.event.id = this.props.event
    }

    this.state = state

    this.getValue = this.getValue.bind(this)
    this.setValue = this.setValue.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleTagChange = this.handleTagChange.bind(this)
    this.handleOfferingChange = this.handleOfferingChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSaveSuccess = this.handleSaveSuccess.bind(this)
    this.handleSaveError = this.handleSaveError.bind(this)
    this.saveData = this.saveData.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.hideConfirmation = this.hideConfirmation.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getDisplaySettings = this.getDisplaySettings.bind(this)
    this.getStreamSettings = this.getStreamSettings.bind(this)
    this.getEventSettings = this.getEventSettings.bind(this)
    this.getCTASettings = this.getCTASettings.bind(this)
    this.getCreditsSettings = this.getCreditsSettings.bind(this)
    this.getRelatedItems = this.getRelatedItems.bind(this)
    this.getTicketSettings = this.getTicketSettings.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    if(this.props.id && this.props.id !== 'new'){
      dispatch(fetchStreamEvent(this.props.id, true))
    }
    window.jQuery('#StreamEventDateTimeInput').datetimepicker({
      defaultDate: this.props.data ? this.props.data.datetime : false,
      locale: 'en-gb'
    })
    window.jQuery('#StreamEventDateTimeInput').on("change.datetimepicker", this.handleInputChange)

    $('.collapse button[name="action"]', $(this.el)).on('blur', (e) => {
      $(e.target).parents('.collapse').first().removeClass('show')
      let index = $(e.target).parents('.card').first().index()
      index = index + 1 > $(e.target).parents('.card').first().siblings().length ? 0 : index + 1
      $('.collapse', $('.card', $(e.target).parents('.accordion').first()).get(index)).addClass('show')
      $('input, select, textarea', $('.card', $(e.target).parents('.accordion').first()).get(index)).first().trigger('focus')
    })

    $('.card input, .card select, .card textarea', $(this.el)).first().trigger('focus')

  }

  componentWillUnmount() {
    $('.collapse button[value="save"]', $(this.el)).off('blur')
  }

  handleInputChange(event) {
    var name, value
    if(event.date){
      name = 'datetime'
      value = event.date.format()
    }else{
      name = event.target.name
      value = event.target.value
    }
    if(name==='datetime'){
      if(value){
        $('#StreamEventDurationInput').attr('required', 'required')
      }else{
        $('#StreamEventDurationInput').removeAttr('required')
      }
    }
    if(name==='stream_type'){
      if(value){
        $('#StreamEventStreamIDInput').attr('required', 'required')
      }else{
        $('#StreamEventStreamIDInput').removeAttr('required')
      }
    }
    if(name==='event'){
      if(value){
        value = this.props.events[value]
      }
    }
    this.setValue(name, value)
  }

  setValue(name, value){
    var tree = name.split('_')
    let data = Object.assign({}, this.state.data)
    var pointer = data
    tree.map((name, i) => {
      pointer[name] = (i+1 === tree.length) ? value : pointer[name] || {}
      pointer = pointer[name]
      return name
    })
    if(name === 'event' && value && value.entity && this.props.entities[value.entity.id]){
      data.entity = this.props.entities[value.entity.id]
    }
    this.setState({
      data: data,
      validated: false,
      changed: true,
      feedbackMessage: ''
    })
  }

  getValue(name){
    if(!this.state.data){
      return ''
    }
    const tree = name.split('.')
    let pointer = this.state.data, item
    while(undefined !== (item = tree.shift())){
      if(!tree.length){
        return pointer[item] || ''
      }
      if(!pointer[item]){
        return ''
      }
      pointer = pointer[item]
    }
  }

  handleTagChange(tags){
    tags = tags.map(tag => tag.text)
    this.setValue('tags', tags)
  }

  handleOfferingChange(data){
    return new Promise((resolve, reject) => {
      this.setValue('offerings', data)
      resolve(true)
    })
  }

  handleSubmit(event){
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    this.saveData(form)
  }

  saveData(form){
    var passed = form.checkValidity()
    this.setState({
      validated: true
    })
    if(this.props.onValidate){
      this.props.onValidate(form)
    }
    if(!passed){
      let invalid = form.querySelectorAll(':invalid');
      for (let item of invalid) {
        $(item).parents('.collapse').first().addClass('show')
      }
      return
    }
    const data = Object.assign({}, this.state.data)
    if(data.stream && data.stream.type === 'restricted'){
      delete data.stream
    }else if(data.stream && data.stream.id && !['youtube', 'vimeo', 'url'].includes(data.stream.type)){
      data.stream.type = 'youtube'
    }
    const { dispatch } = this.props
    // Submit post
    if(this.props.data && data.action !== 'new'){
      return dispatch(patchStreamEvent(data))
      .then(result => {
        return this.handleClose(data)
      })
    }else{
      delete data.id
      delete data.action
      return dispatch(postStreamEvent(data))
      .then(result => {
        return this.handleClose(data)
      })
      .catch(err => {
        console.log(err.message)
      })
    }
  }

  handleClose(data){
    const { dispatch, id } = this.props
    const promise = dispatch(showModal('streamevents',id, false))
    if(data.event && data.event.id){
      dispatch(setFeedScrollPosition('event-' + data.event.id, id))
    }
    return promise
  }

  handleSaveSuccess(data){
    this.setState({
      validated: false,
      changed: false,
      feedbackMessage: 'Stream event saved successfully'
    })
    //$('form#EditForm').trigger("reset")
    if(this.props.handleSaveSuccess){
      this.props.handleSaveSuccess(data)
    }
  }

  handleSaveError(err){
    this.setState({
      validated: false
    })
    if(this.props.handleSaveError){
      this.props.handleSaveError(err)
    }
  }

  handleDelete(){
    if(this.props.data && this.props.data.id){
      const { dispatch } = this.props
      dispatch(deleteStreamEvent(this.props.data.id))
        .then(result => {
          this.props.handleClose()
        })
    }
    this.hideConfirmation()
  }

  hideConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: false}}
    })
  }

  showConfirmation(){
    this.setState({
      confirmation: {...this.state.confirmation, ...{visible: true}}
    })
  }

  getSection(section, title, icon, content){

    if(!content){
      return null
    }

    if(Array.isArray(content)){
      for(let i=0; i<content.length; i++){
        if(!content[i]){
          return null
  }
    }
    }

    const { index } = section

    const eventKey = String(index)

    section.index++

    return <React.Fragment>
      <Card>
        <AccordionToggle className="text-secondary" variant="link" eventKey={eventKey}>
        <span className="card-header__icon text-muted d-inline-block text-center me-1"><FontAwesomeIcon icon={icon} /></span>
          {title}
        </AccordionToggle>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>

            {content}

            {/*<div className="text-end">
              <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
                Save
      </Button>
            </div>*/}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </React.Fragment>
  }

  getDisplaySettings(){
    return <React.Fragment>
              <Form.Group controlId="StreamEventTitleInput">
                <Form.Label className="mt-2 mb-0">Title</Form.Label>
                <Form.Control name="title" onChange={this.handleInputChange} placeholder="The stream event's title" value={this.getValue('title')} required />
                <Form.Control.Feedback type="invalid">
                  Please add a title for the stream event
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="StreamEventSubTitleInput">
                <Form.Label className="mt-2 mb-0">Description</Form.Label>
                <Form.Control as="textarea" rows="3" name="subtitle" onChange={this.handleInputChange} placeholder="A brief description of the stream event (one sentence)" value={this.getValue('subtitle')} />
              </Form.Group>

              <Form.Group controlId="StreamEventImageInput">
                <Form.Label className="mt-2 mb-0">Image</Form.Label>
                  <div className="input-group align-items-start" id="StreamEventImageInput" data-target-input="nearest">
                    <ImageSelector
                      image={this.getValue('image')}
                      name="image"
                      handleChange={this.setValue}
                      />
                  </div>
              </Form.Group>

              <Form.Group controlId="StreamEventLogoInput">
                <Form.Label className="mt-2 mb-0">Logo</Form.Label>
                  <div className="input-group align-items-start" id="StreamEventLogoInput" data-target-input="nearest">
                    <ImageSelector
                      image={this.getValue('logo')}
                      name="logo"
                      type="logo"
                      handleChange={this.setValue}
                      />
                  </div>
                  <Form.Text className="text-muted">
                    Logo to be displayed on the stream's preview page
                  </Form.Text>
              </Form.Group>

              <Form.Group controlId="StreamEventVisiblityInput">
                <Form.Label className="mt-2 mb-0">Visibility</Form.Label>
                <Form.Control as="select" name="visibility" onChange={this.handleInputChange} value={this.getValue('visibility')}>
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </Form.Control>
              </Form.Group>
    </React.Fragment>
  }

  getStreamSettings(){

    const { data } = this.props

    let tags = []
    if(data && data.tags){
      tags = data.tags.map(tag => {
        return { id: tag, text: tag}
      })
    }

    return <React.Fragment>
              <Form.Group controlId="StreamEventDateTimeInput">
                <Form.Label className="mt-2 mb-0">Start</Form.Label>
                  <div className="input-group date" id="StreamEventDateTimeInput" data-target-input="nearest">
                    <input name="datetime" onChange={this.handleInputChange} type="text" placeholder="The stream's start time" className="form-control datetimepicker-input" data-target="#StreamEventDateTimeInput" />
                    <div className="input-group-append" data-target="#StreamEventDateTimeInput" data-toggle="datetimepicker">
                <div className="input-group-text"><FontAwesomeIcon icon={faCalendar} /></div>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please select a start time for the stream
                    </Form.Control.Feedback>
                  </div>
              </Form.Group>

              <Form.Group controlId="StreamEventDurationInput">
                <Form.Label className="mt-2 mb-0">Duration</Form.Label>
                <Form.Control name="duration" onChange={this.handleInputChange} placeholder="The stream's approximate duration in minutes" value={this.getValue('duration')} />
                <Form.Control.Feedback type="invalid">
                  Please add an approximate duration for the stream
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="StreamEventSubStreamTypeInput">
                <Form.Label className="mt-2 mb-0">Stream type</Form.Label>
                <Form.Control as="select" name="stream_type" onChange={this.handleInputChange} value={this.getValue('stream.type')}>
                  <option value="youtube">YouTube</option>
                  <option value="vimeo">Vimeo</option>
                  <option value="url">URL</option>
                </Form.Control>
              </Form.Group>

             <Form.Group controlId="StreamEventStreamIDInput">
                <Form.Label className="mt-2 mb-0">Video URL</Form.Label>
                <Form.Control name="stream_id" onChange={this.handleInputChange} placeholder="The video url" value={this.getValue('stream.id')} />
                <Form.Control.Feedback type="invalid">
                  Please add a video URL
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="StreamEventAccessInput">
                <Form.Label className="mt-2 mb-0">Access</Form.Label>
                <Form.Control as="select" name="access" onChange={this.handleInputChange} value={this.getValue('access')}>
                  <option value="inherit">Inherit from event</option>
                  <option value="open">Open</option>
                  <option value="ticket">Ticketed</option>
                </Form.Control>
                  <Form.Text className="text-muted">
                    Controls users access to the event - either inherit from the parent event, open access to all or available only to ticket holders
                  </Form.Text>
              </Form.Group>

              <Form.Group controlId="StreamEventTagsInput">
                <Form.Label className="mt-2 mb-0">Tags</Form.Label>
                <TagSelector
                  tags={tags}
                  suggestions={this.props.eventData && this.props.eventData.tags ? this.props.eventData.tags.map(tag => ({ id: tag, text: tag})) : null }
                  handleChange={this.handleTagChange}
                />
                 <Form.Text className="text-muted">
                    Tags are used to filter videos in a feed
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="StreamEventRatingInput">
                  <Form.Label className="mt-2 mb-0">Rating</Form.Label>
                  <Form.Control as="select" name="rating" onChange={this.handleInputChange} value={this.getValue('rating')}>
                    <option value="">None</option>
                    <option value="5">&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                    <option value="4">&#9733; &#9733; &#9733; &#9733;</option>
                    <option value="3">&#9733; &#9733; &#9733;</option>
                    <option value="2">&#9733; &#9733;</option>
                    <option value="1">&#9733;</option>
                  </Form.Control>
                </Form.Group>

    </React.Fragment>
  }

  getEventSettings(){

    const { events } = this.props

    const eventSelect = (events && Object.keys(events).length) ?
      <Form.Group controlId="StreamEventEventInput">
        <Form.Label className="mt-2 mb-0">Event</Form.Label>
        <Form.Control as="select" name="event" onChange={this.handleInputChange} value={this.getValue('event.id')}>
                    <option value="">None</option>
          {Object.keys(events).map(key => (
            <option value={key}>{events[key].name}</option>
          ))}
                  </Form.Control>
                </Form.Group>
      : null

    return <React.Fragment>
              {eventSelect}

              <Form.Group controlId="StreamEventDetailsURLInput">
                <Form.Label className="mt-2 mb-0">URL</Form.Label>
                <Form.Control name="details_url" onChange={this.handleInputChange} placeholder="A URL to a 'more details' or tickets page"  value={this.getValue('details.url')} />
              </Form.Group>

              <Form.Group controlId="StreamEventDetailsTextInput">
                <Form.Label className="mt-2 mb-0">Label</Form.Label>
                <Form.Control name="details_text" onChange={this.handleInputChange} placeholder="Label for the 'more details' button (default: 'More details')"  value={this.getValue('details.text')} />
              </Form.Group>
    </React.Fragment>
  }

  getCTASettings(){

    const content = this.getValue('merchandise.content')

    return <React.Fragment>

     <Form.Group controlId="StreamEventMerchandiseContentInput">
        <Form.Label className="mt-2 mb-0">Content</Form.Label>
        <Form.Control as="select" name="merchandise_content" onChange={this.handleInputChange} value={this.getValue('merchandise.content')}>
          <option value="url">Link</option>
          <option value="related">Related items</option>
          <option value="share">Share</option>
        </Form.Control>
      </Form.Group>

      { (!content || content === 'url') ? <Form.Group controlId="StreamEventMerchandiseURLInput">
        <Form.Label className="mt-2 mb-0">Call-to-action URL</Form.Label>
        <Form.Control name="merchandise_url" onChange={this.handleInputChange} placeholder="The URL for merchandise"  value={this.getValue('merchandise.url')} />
         <Form.Text className="text-muted">
            This URL can be used to direct users to a merchandise page or other call-to-action at the end of the video
          </Form.Text>
      </Form.Group> : null }

      { (!content || content === 'url') ? <Form.Group controlId="StreamEventMerchandiseTextInput">
        <Form.Label className="mt-2 mb-0">Call-to-action button label</Form.Label>
        <Form.Control name="merchandise_text" onChange={this.handleInputChange} placeholder="Label for the merchandise button"  value={this.getValue('merchandise.text')} />
      </Form.Group> : null}

              <Form.Group controlId="StreamEventPayPalDonateIDInput">
                <Form.Label className="mt-2 mb-0">PayPal button ID</Form.Label>
                <Form.Control name="paypal_donate_id" onChange={this.handleInputChange} placeholder="The ID of a preset PayPal button"  value={this.getValue('paypal.donate.id')} />
              </Form.Group>

              <Form.Group controlId="StreamEventPayPalDonateTextInput">
                <Form.Label className="mt-2 mb-0">PayPal button label</Form.Label>
                <Form.Control name="paypal_donate_text" onChange={this.handleInputChange} placeholder="Label for the PayPal button"  value={this.getValue('paypal.donate.text')} />
              </Form.Group>

    </React.Fragment>
  }

  getCreditsSettings(){

    const content = this.getValue('credits.content')

    return <React.Fragment>
      <Form.Group controlId="StreamEventCreditsTitleInput">
        <Form.Label className="mt-2 mb-0">Pop-up title</Form.Label>
        <Form.Control name="credits_title" onChange={this.handleInputChange} placeholder="The pop-up title"  value={this.getValue('credits.title')} />
                 <Form.Text className="text-muted">
            Enter a message to appear at the end of the video. If a merchandise URL has been specified above, that will also be included
                  </Form.Text>
              </Form.Group>

     <Form.Group controlId="StreamEventCreditsContentInput">
        <Form.Label className="mt-2 mb-0">Content</Form.Label>
        <Form.Control as="select" name="credits_content" onChange={this.handleInputChange} value={this.getValue('credits.content')}>
          <option value="text">Text</option>
          <option value="related">Related items</option>
          <option value="share">Share</option>
        </Form.Control>
              </Form.Group>

      { (!content || content === 'text') ? <Form.Group controlId="StreamEventCreditsBodyInput">
        <Form.Label className="mt-2 mb-0">Pop-up text</Form.Label>
        <Form.Control as="textarea" rows="3" name="credits_body" onChange={this.handleInputChange} placeholder="The pop-up body text"  value={this.getValue('credits.body')} />
      </Form.Group> : null }
    </React.Fragment>
  }

  getRelatedItems(){

    const data = this.getValue('related')

    const {entity} = this.props

    if(!entity){
      return null
    }

    return <React.Fragment>
                 <Form.Text className="text-muted">
        These items can be linked to from the call-to-action button or the end credits
                  </Form.Text>
      <RelatedItems
        items={data}
        container={this.props.modalBody}
        name="related"
        handleChange={this.setValue}
        entity={entity.id}
         />
    </React.Fragment>
  }



  getTicketSettings(){

    const { data, owner } = this.props

    if(!owner){
      return null
    }

    return <OfferingList
        data={ data ? data.offerings : []}
        handleChange={this.handleOfferingChange}
      />
  }



  render() {

    const errorStyle = {
      display: (this.props.serverError === '') ? 'none' : 'block'
    }

    const feedbackStyle = {
      display: (this.props.feedbackMessage === '') ? 'none' : 'block'
    }

    const deleteButton = this.props.data ?
      <Button variant="danger" type="button" className="ms-2" onClick={this.showConfirmation}>
        Delete
      </Button>
      : null

    const newButton = this.props.id ?
      <Button name="action" value="new" onClick={this.handleInputChange} variant="dark" type="submit" className="ms-2" title="Click to create a new stream event with these settings">
        Duplicate …
                  </Button>
      : null

    const section = {
      index: 0
    }

    return (
      <Form ref={el => this.el = el} novalidate="true" validated={this.state.validated} onSubmit={this.handleSubmit} className="bg-white">

        <Accordion defaultActiveKey="0">

          {this.getSection(
            section,
            'Display Settings',
            faImage,
            this.getDisplaySettings()
          )}

          {this.getSection(
            section,
            'Stream Settings',
            faVideo,
            this.getStreamSettings()
          )}


          {this.getSection(
            section,
            'Event Settings',
            faCalendarDay,
            this.getEventSettings()
          )}

          {this.getSection(
            section,
            'Call-to-action Settings',
            faBullhorn,
            this.getCTASettings()
          )}

          {this.getSection(
            section,
            'Related Items',
            faCartPlus,
            this.getRelatedItems()
          )}

          {this.getSection(
            section,
            'Credits',
            faFilm,
            this.getCreditsSettings()
          )}

         {this.getSection(
            section,
            'Tickets',
            faTicketAlt,
            this.getTicketSettings()
          )}


        </Accordion>


        <Form.Group style={errorStyle}>
          <Form.Control.Feedback type="invalid">
            {this.props.serverError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group style={feedbackStyle}>
          <Form.Control.Feedback type="valid-feedback">
            {this.state.feedbackMessage}
          </Form.Control.Feedback>
        </Form.Group>

          <Button variant="secondary" type="button" onClick={this.props.handleClose}>
            Close
          </Button>
          { deleteButton }
          {newButton}
          <Button name="action" value="save" variant="primary" type="submit" className="ms-2">
            Save
          </Button>

        <ConfirmationModal
          title="Delete stream event"
          body="Deleting a stream event can't be undone. Are you sure about this?"
          handleDismissal={this.hideConfirmation}
          handleConfirmation={this.handleDelete}
          visible={this.state.confirmation.visible}
        />

      </Form>
    )
  }
}

EditForm.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const { user: { administrator } } = state
  const data = getStreamEvent(state, ownProps.id)
  const eventData = ownProps.event ? getEvent(state, ownProps.event) : (data && data.event ? getEvent(state, data.event.id) : null)
  const entity = eventData ? getEventEntity(state, eventData.id) : null
  const owner = isEventOwner(state, eventData.id)

  const events = {}

  if(administrator && administrator.events && Array.isArray(administrator.events)){
    administrator.events.map((id, index) => {
      events[id] = state.events[id]
      return id
    })
  }

  const entities = {}

  if(administrator && administrator.entities && Array.isArray(administrator.entities)){
    administrator.entities.map((id, index) => {
      entities[id] = state.entities[id]
      return id
    })
  }


  return {
    data,
    events,
    entities,
    eventData,
    entity,
    owner
  }
}

export default connect(mapStateToProps)(EditForm)