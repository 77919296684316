import './Variables.css'
import './sass/App.scss'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Player from './components/player/Player'
import ChatWindow from './components/chat/ChatWindow'
import FeedWindow from './components/feed/FeedWindow'
import EventFeedWindow from './components/event/FeedWindow'
import EventFoyerWindow from './components/event/FoyerWindow'
import EntityFeedWindow from './components/entity/FeedWindow'
import EntityEditWindow from './components/entity/EditWindow'
import EntityTicketHolderWindow from './components/entity/TicketHolders'
import VenueEditWindow from './components/venue/EditWindow'
import ProductFeedWindow from './components/product/FeedWindow'
import ProductWindow from './components/product/ProductWindow'
import UserFeedWindow from './components/user/FeedWindow'
import UserOrderWindow from './components/user/OrderWindow'
import SettingsWindow from './components/settings/SettingsWindow'
import CheckoutWindow from './components/cart/PaymentWindow'
import RegistrationWindow from './components/event/RegistrationWindow'
import ContentWindow from './components/content/ContentWindow'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './redux/store'
import { handleAuthToken } from './redux/auth/actions'
import { fetchTokens } from './redux/token/actions'
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom'

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

const App = ({ children }) => {

  const history = useHistory()
  const store = configureStore()

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH') {
        store.dispatch(fetchTokens(true))
      }
    })
    return function cleanup() {
      unlisten()
    }
  }, [])

  useEffect(() => {
    store.dispatch(handleAuthToken())
    .then(result => {
      if(!result){
        store.dispatch(fetchTokens())
      }
    })
  }, [])

  return (
    <Provider store={store}>
        <Switch>
          <Route path="/player/:id" component={Player} />
          <Route path="/:resource/:id/chat" component={ChatWindow} />
          <Route path="/feed" component={FeedWindow} />
          <Route path="/events/:id/feed" component={EventFeedWindow} />
          <Route path="/events/:id/foyer" component={EventFoyerWindow} />
          <Route path="/entities/:id/feed" component={EntityFeedWindow} />
          <Route path="/entities/:id/edit" component={EntityEditWindow} />
          <Route path="/entities/:id/products/feed" component={ProductFeedWindow} />
          <Route path="/entities/:id/ticket-holders/" component={EntityTicketHolderWindow} />
          <Route path="/products/:id" component={ProductWindow} />
          <Route path="/entities/:id/checkout" component={CheckoutWindow} />
          <Route path="/venues/:id/edit" component={VenueEditWindow} />
          <Route path="/users/:id/feed" component={UserFeedWindow} />
          <Route path="/users/:id/orders" component={UserOrderWindow} />
          <Route path="/:resource/:id/feed" component={FeedWindow} />
          <Route path="/users/:id/settings/:group" component={SettingsWindow} />
          <Route path="/users/:id/settings" component={SettingsWindow} />
          <Route path="/events/:id/register" component={RegistrationWindow} />
          <Route path="/privacy-policy" >
            <ContentWindow component="PrivacyPolicy" />
          </Route>
          <Route>
            <ContentWindow component="HomePage" />
          </Route>
        </Switch>
    </Provider>
  )
}

ReactDOM.render(<AppWrapper />, document.getElementById("root"))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()