import React from 'react';
import Modal from '../display/Modal';
import ModalButton from '../display/ModalButton';
import SocialButtons from '../social/SocialButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { showModal } from '../../redux/ui/actions'
import { getStreamEventGiftables } from '../../redux/state'
import GiftListItem from './GiftListItem'

class ShareButton extends React.Component {

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    const { giftable } = this.props

    const gifting = (giftable && giftable.length) ?
    <div>
      <p className="py-2">Share with friends or family and they'll get a discount:</p>
      <div className="mt-3">
        {giftable.map(item => {
          return <GiftListItem key={item} item={item} className="mb-2" />
        })}
      </div>
    </div>
    : null

    const size = gifting ? 'lg' : 'sm'

    const content = <React.Fragment>
      {gifting}
      <div className="w-100 text-center"><SocialButtons className="social-buttons"
        handleClick={this.handleClick}
        url={this.props.url}
        /></div>
    </React.Fragment>

    const icon = <FontAwesomeIcon icon={faShareAlt} />

    return <React.Fragment>
      <ModalButton
        resource="share"
        id={ this.props.resource + '-' + this.props.id }
        mode={this.props.mode || 'icon'}
        icon={icon}
        className={this.props.className}
        label={ this.props.label || 'Share'}
        variant={this.props.variant}
        size={this.props.size}
      />
      <Modal
        resource="share"
        id={ this.props.resource + '-' + this.props.id }
        title={this.props.title || 'Share'}
        content={content}
        size={size}
      />
    </React.Fragment>

  }

  handleClick(e){
    const { dispatch } = this.props
    return dispatch(showModal('share',this.props.resource + '-' + this.props.id, false))
  }


}

const mapStateToProps = (state, ownProps) => {

  const giftable = getStreamEventGiftables(state, ownProps.id)

  return {
    giftable
  }
}


export default connect(mapStateToProps)(ShareButton)