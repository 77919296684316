import { getEntity } from "../state"

/**
 * Returns an array of entitiy children's IDs
 * @param {object} state
 * @param {string} id
 * @returns {Array<string>}
 */
export const getEntityChildren = (state, id) => {
  const { entities } = state
  if(!entities || !entities.length) return null
  return entities.filter(item => item.parent === id).map(item => item.id)
}

/**
 * Returns true if an entity is loaded
 * @param {object} state
 * @param {string} id
 * @returns {boolean}
 */
 export const entityIsLoaded = (state, id) => {
  const { entities } = state
  return entities[id] && entities[id].name
}

/**
 * Returns terms and conditions for an entity
 * @param {object} state
 * @param {string} id
 * @returns {mixed}
 */
export const getEntityTerms = (state, id) => {
  const entity = getEntity(state, id)
  return entity && entity._ecommerce && entity._ecommerce.terms && entity._ecommerce.terms.content ? entity._ecommerce.terms : null
}

/**
 * Returns the search term used in a ticket holder search
 * @param {object} state
 * @param {string} id
 * @returns {string}
 */
export const getTicketHolderSearch = (state, id) => {
  const entity = getEntity(state, id)
  return entity && entity.ticketHolders ? entity.ticketHolders.search || '' : ''
}

/**
 * Returns the data returned by ticket holder search
 * @param {object} state
 * @param {string} id
 * @returns {Array}
 */
 export const getSearchedTicketHolders = (state, id) => {
  const entity = getEntity(state, id)
  return entity && entity.ticketHolders ? entity.ticketHolders.data || {} : {}
}