import React, { useEffect, useState } from "react"

function CountdownTimer(props) {
  const calculateTimeLeft = () =>{
      const difference = +new Date(props.date) - +new Date()
      let timeLeft = {}

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        }
        if(props.round){
          if(timeLeft.days){
            timeLeft = { days: timeLeft.days }
          }else if(timeLeft.hours){
            timeLeft = { hours: timeLeft.hours }
          }else if(timeLeft.minutes){
            timeLeft = { minutes: timeLeft.minutes }
          }else{
            timeLeft = { seconds: timeLeft.seconds }
          }
        }
      }

      return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    let interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return function cleanup() {
        clearInterval(interval)
    }
  })

  const timerComponents = []
  let label

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return
    }
    label = (timeLeft[interval] === 1) ? interval.replace(/s$/, '') : interval
    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {label}{" "}
      </span>
    )
  })

  const date = new Date(props.date)
  const options = {
    year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
    hour12: false,
    timeZone: 'Europe/London'
  }
  const dateString = new Intl.DateTimeFormat('en-GB', options).format(date)

  if(!timerComponents.length && props.onReady && props.onReady instanceof Function){
    props.onReady()
  }

  return (
    <span className={props.className}>
    {timerComponents.length ? <span>{ props.label || 'Starting' } in {timerComponents}</span> : <span>Started on {dateString}</span>}
    </span>
  )

}

export default CountdownTimer