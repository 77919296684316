import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getTenantCart } from '../../redux/state'

class Header extends React.Component {

  render() {

    let cssClass = 'm-0 py-3'
    let titleClass = 'border-bottom'
    let rowClass = 'banner banner--header pt-5'
    let style = null

    if(this.props.image){
      cssClass += ' text-white'
      titleClass = 'banner__title'
      rowClass += ' banner--payment'
      style = {backgroundImage: 'url(' + this.props.image + ')'}
    }

    return (
      <Row className={rowClass} style={style}>
        <Col className={titleClass}>
          <h1 className={cssClass}>Checkout</h1>
        </Col>
      </Row>
    )
  }

}

Header.propTypes = {
  image: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {

  const cart = getTenantCart(state)

  const { events, streamevents, products } = state

  const resources = {events, streamevents, products}

  let image = null

  if(cart && cart.items){
    cart.items.map(item => {
      if(!image && item.offering && item.offering.resource && resources[item.offering.resource.type][item.offering.resource.id]){
        if(resources[item.offering.resource.type][item.offering.resource.id].image){
          image =  resources[item.offering.resource.type][item.offering.resource.id].image
        }else if(resources[item.offering.resource.type][item.offering.resource.id].images && resources[item.offering.resource.type][item.offering.resource.id].images.length){
          image =  resources[item.offering.resource.type][item.offering.resource.id].images[0].url
        }
      }
      return item
    })
  }

  return {
    image
  }
}

export default connect(mapStateToProps)(Header)