import React from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import DeleteButton from './DeleteButton'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

class ListItem extends React.Component {

  getEditButton(){
    const { handleEdit, index, data } = this.props
    if(!handleEdit){
      return null
    }
    const onClick = (e) => {
      e.preventDefault()
      e.stopPropagation()
      handleEdit(index)
    }

    let cssClass = 'me-2'

    if(!data.active){
      cssClass += ' text-muted'
    }

    return <button className={cssClass} title="Edit" onClick={onClick}>
      <FontAwesomeIcon icon={faEdit} />
    </button>

  }

  render() {

    const { data } = this.props

    const price = Number(data.price.value).toFixed(2)
    const symbols = {
      GBP: '£',
      USD: '$',
      EUR: '€'
    }
    const currency = Object.keys(symbols).includes(data.price.currency) ? symbols[data.price.currency] : data.price.currency || '£'
    const info = [
      'Price: ' + currency + price + '/kg'
    ]

    let maxPrice = Number(data.price.value)

    if(data.duration){
      info.push('Delivery: ' + data.duration + ' working day' + (data.duration > 1 ? 's' : ''))
    }

    if(data.weight && data.weight.max){
      const maxWeight = data.weight.max >= 1 ? data.weight.max +'kg' : data.weight.max*1000 + 'g'
      info.push('Max weight: ' + maxWeight)
      maxPrice *= data.weight.max
    }

    maxPrice = maxPrice.toFixed(2)

    const dimensions = []
    const label = []
    if(data.length && data.length.max){
      label.push('L')
      dimensions.push(data.length.max + 'cm')
    }
    if(data.width && data.width.max){
      label.push('W')
      dimensions.push(data.width.max + 'cm')
    }
    if(data.height && data.height.max){
      label.push('H')
      dimensions.push(data.height.max + 'cm')
    }

    if(dimensions.length){
      info.push('Max dimensions (' + label.join('x') + '): ' + dimensions.join(' x '))
    }

    let cssClass = 'bg-light'
    let badgeVariant = 'primary'

    if(this.props.className){
      cssClass += ' ' + this.props.className
    }

    const deleteButton = <DeleteButton id={this.props.index} handleDelete={this.props.handleDelete} className={ !data.active ? 'text-muted': '' } />

    if(!data.active){
      cssClass += ' text-muted'
      badgeVariant = 'secondary'
    }

    return (
      <Card className={cssClass}>
        <Card.Body className="d-flex justify-content-between px-3 py-3 align-items-start">
          <Badge bg={badgeVariant} className="me-3">{ currency + maxPrice}</Badge>
          <span className="flex-grow-1">
            <h6 className="mt-0 mb-2">{data.name}</h6>
            <Form.Text className="text-muted mb-0">{info.join('; ')}</Form.Text>
          </span>
          {this.getEditButton()}
          {deleteButton}
        </Card.Body>
      </Card>
    )

  }


}

export default ListItem