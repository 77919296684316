import React from 'react';
import Menu from '../navigation/Menu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PrivacyPolicy from './PrivacyPolicy'
import HomePage from './HomePage'
import NotFound from './NotFound'
import Footer from '../navigation/Footer'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {trackPageView, initGA} from '../tracking/Google'
import { setCurrentTenant } from '../../redux/ui/actions'

class ContentWindow extends React.Component {

  constructor(props){
    super(props)
    this.getContent = this.getContent.bind(this)

  }

  componentDidMount(){
    const { dispatch } = this.props
    dispatch(setCurrentTenant('entities', 'zarucchi'))
    initGA(window.gaTrackingCode)
    trackPageView()
  }

  getContent(){
    switch(this.props.component){
      case 'PrivacyPolicy':
        return <PrivacyPolicy />
      case 'HomePage':
        return <HomePage />
      default:
        return <NotFound />
    }
  }

  render() {

    return (
      <React.Fragment>
        <Container className="bg-white content-window">
          <Menu />

          {this.getContent()}

        </Container>
        <Footer />
      </React.Fragment>
    )

  }
}

ContentWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  const { user } = state

  return {
    authenticated: user && user.status && user.status === 'fetched'
  }
}

export default connect(mapStateToProps)(ContentWindow)