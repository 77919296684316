import React from 'react';
import Button from 'react-bootstrap/Button';
import TicketModal from './TicketModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { showModal } from '../../redux/ui/actions'
import { connect } from 'react-redux'
import { getEventOfferings, getEvent } from '../../redux/state'

class TicketButton extends React.Component {

  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {

    const { hasTickets, label, variant, id, mode, className, size } = this.props

    if(!hasTickets){
      return null
    }

    const modal = <TicketModal
      id={id}
      resource="events"
      />

    if(mode === 'icon'){
      return (
        <React.Fragment>
          <button title={label} onClick={this.handleClick} className={className}>
            <FontAwesomeIcon icon={faTicketAlt} />
          </button>
          {modal}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Button variant={variant || 'light'} title={label} onClick={this.handleClick} className={className} size={size}>
          <FontAwesomeIcon icon={faTicketAlt} className="me-2" /><span className="text-nowrap">{label}</span>
        </Button>
        {modal}
      </React.Fragment>
    )
  }

  handleClick(e){
    e.stopPropagation()
    const { dispatch } = this.props
    dispatch(showModal('tickets-events',this.props.id, true))
    if(this.props.handleClick){
      this.props.handleClick(e)
    }
  }


}

const mapStateToProps = (state, ownProps) => {
  const { user } = state

  // Check for owner
  const authenticated = (user && user.id)

  const hasTickets = Boolean(getEventOfferings(state, ownProps.id))

  const event = getEvent(state, ownProps.id)

  return {
    authenticated,
    hasTickets,
    label : event && event.feed && event.feed.ticket && event.feed.ticket.label ? event.feed.ticket.label : 'Buy tickets'
  }
}

export default connect(mapStateToProps)(TicketButton)