// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Settings Window CSS\n */\n.SettingsWindow_mainMenu__7NkOl a, .SettingsWindow_mainMenu__7NkOl button {\n  color: #777;\n  font-size: 1.5rem;\n  margin: 0.5rem 0;\n}\n\n.SettingsWindow_breadcrumb__XC9WB {\n  margin: 1rem 0;\n  font-size: 0.9em;\n}\n.SettingsWindow_breadcrumb__XC9WB a {\n  color: #FF8100;\n  text-decoration: none;\n}", "",{"version":3,"sources":["webpack://./src/components/settings/SettingsWindow.module.scss"],"names":[],"mappings":"AAAA;;EAAA;AAKI;EACI,WAAA;EACA,iBAAA;EACA,gBAAA;AADR;;AAKA;EACI,cAAA;EACA,gBAAA;AAFJ;AAGI;EACI,cAAA;EACA,qBAAA;AADR","sourcesContent":["/**\n * Settings Window CSS\n */\n\n.mainMenu{\n    a, button{\n        color: #777;\n        font-size: 1.5rem;\n        margin: 0.5rem 0;\n    }\n}\n\n.breadcrumb{\n    margin: 1rem 0;\n    font-size: 0.9em;\n    a{\n        color: #FF8100;\n        text-decoration: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainMenu": "SettingsWindow_mainMenu__7NkOl",
	"breadcrumb": "SettingsWindow_breadcrumb__XC9WB"
};
export default ___CSS_LOADER_EXPORT___;
