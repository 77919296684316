import React from 'react';
import $ from 'jquery';
import Feed from './Feed';
import Menu from '../navigation/Menu';
import Header from '../entity/Header';
import TagFilter from '../feed/TagFilter';
import SearchForm from '../feed/SearchForm';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux'
import { fetchTokens } from '../../redux/token/actions'
import { fetchEntity } from '../../redux/entity/actions'
import { setCurrentTenant } from '../../redux/ui/actions'
import { log } from '../../redux/logging/actions'
import PropTypes from 'prop-types'
import TextBlock from '../display/TextBlock'
import Footer from '../navigation/Footer'
import ShareButton from '../social/ShareButton'
import EditButton from './EditButton'
import { fetchUserAccessPasses } from '../../redux/access/actions'
import { fetchUserGifts } from '../../redux/shopping/actions'
import { getEntity, getTenantEntity, isEntityAdmin } from '../../redux/state'
import {trackPageView, initGA} from '../tracking/Google'
import Breadcrumb from '../navigation/Breadcrumb'

class FeedWindow extends React.Component {

  constructor(props){
    super(props)
    this.renderBreadcrumb = this.renderBreadcrumb.bind(this)
  }

  componentDidMount() {

    const { dispatch } = this.props
    const load = () => {
      return dispatch(fetchEntity(this.props.id, true))
      .then(() => {
        document.title = this.props.entity.name + ' Shop'
        initGA(window.gaTrackingCode)
        trackPageView()
        dispatch(setCurrentTenant('entities', this.props.entity.id))
        return dispatch(log('entities', this.props.entity.id, 'feed', { view: 'products', name: this.props.entity.name}))
      })
    }

    if(!this.props.authenticated){
      load()
        .then(() => {
          return dispatch(fetchTokens())
        })
    }else{
      load()
    }

  }

  componentDidUpdate() {
    const { dispatch, entity, tenant } = this.props
    if(entity && (!tenant || tenant.id !== entity.id)){
      dispatch(setCurrentTenant('entities', entity.id))
      document.title = entity.name + ' Shop'
      initGA(window.gaTrackingCode)
      trackPageView()
    }
  }

  renderBreadcrumb() {
    const { entity } = this.props

    if(!entity){
      return null
    }

    const trail = [
      { path: '/entities/' + (entity.alias || entity.id) + '/feed', label: entity.name},
      { path: '/entities/' + (entity.alias || entity.id) + '/products/feed', label: 'Shop'}
    ]

    return <Row>
      <Col className="pt-2 pb-1">
        <Breadcrumb trail={trail} className="mb-0" />
      </Col>
    </Row>
  }

  getAddButton(){
    return this.props.entity && this.props.administrator ?
      <EditButton id="new" entity={this.props.entity ? this.props.entity.id : null } variant="light" className="ms-3" />
      : null
  }


  render() {

    const { entity } = this.props

    if(!entity){
      return null
    }

    const feedName = 'entity-' + entity.id + '-products'

    const tagFilter = entity.tags ?
      <div className="bg-white">
        <TagFilter
          tags={entity.tags}
          feed={feedName}
          entity={entity}
         />
       </div>
      : null

    return (
      <React.Fragment>
        <Container className="bg-white feed-window">
          <Menu
            addButton={this.getAddButton()} />
          {this.renderBreadcrumb()}
            <Row>
              <Col className="pt-2 pb-2">
                <Header
                  id={entity.id}
                />
              </Col>
            </Row>
            <Row className="feed-header__row">
              <Col className="feed-header" xs={12}>
                <div className="feed-header__controls d-block d-md-flex align-items-start justify-content-between py-2 bg-white mx-n1 px-1">
                  {tagFilter}
                  <div className="ms-auto mt-2 mb-1 d-flex justify-content-end">
                    <SearchForm feed={feedName} className="text-end d-flex justify-content-between flex-grow-1" />
                    <ShareButton
                      className="text-secondary ms-2 pl-2 ms-md-3 ps-md-3 border-start feed-header__controls__share"
                      resource="entities"
                      mode="icon"
                      id={entity.id}
                      title="Share this page"
                      />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="feed-container">
              <Row>
                <Col xs={12}>
                  <Feed
                    key={feedName}
                    name={feedName}
                    id={entity.id}
                  />
                </Col>
            </Row>
            </div>
        </Container>
        <Footer />
      </React.Fragment>
    );

  }

}

FeedWindow.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  entity: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match && ownProps.match.params ? ownProps.match.params.id : null

  const { user } = state

  const entity = getEntity(state, id)

  return {
    authenticated: user && user.status && user.status === 'fetched',
    administrator: entity && isEntityAdmin(state, entity.id),
    entity,
    tenant: getTenantEntity(state)
  }
}

export default connect(mapStateToProps)(FeedWindow)