import React from 'react'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AddButton from '../cart/AddButton'
import RemoveButton from '../cart/RemoveButton'
import { getTenantCart, getTenantEntity, getPaymentEntity } from '../../redux/state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

class Purchase extends React.Component {

  render() {

    const { offering, quantity, className } = this.props

    if(!offering || !offering.active){
      return null
    }

    let net = Number(offering.net)
    const info = []

    let price = net

    if(offering.vat){
      price *= ((100 + Number(offering.vat))/100)
    }

    if(offering.access && offering.access.lifespan && offering.access.lifespan.value){
      info.push('Valid for ' + offering.access.lifespan.value + ' ' + offering.access.lifespan.unit + (offering.access.lifespan.value > 1 ? 's' : '') + ' after first use.')
    }

    if(offering.gifting && offering.gifting.id){
      info.push(<span><strong>{offering.gifting.reduction}% discount</strong> shared by {offering.gifting.originator.name}</span>)
    }

    if(quantity){
      info.push(<span>You have <strong>{quantity}</strong> in your cart. <RemoveButton mode="inline" offering={offering} >Remove</RemoveButton>.</span>)
    }

    price = price.toFixed(2)

    let cssClass = 'bg-light'
    const badgeVariant = quantity ? 'success' : (offering.gifting && offering.gifting.id ? 'danger' : 'primary')

    if(className){
      cssClass += ' ' + className
    }

    const tooltip = offering.terms ?
      <OverlayTrigger
        key="tooltip-terms"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${offering.id}`}>
            {offering.terms}
          </Tooltip>
        }
      >
        <a className="text-muted ms-1" as="button"><FontAwesomeIcon icon={faInfoCircle} /></a>
      </OverlayTrigger>
      :
      null

    return (
      <Card className={cssClass}>
        <Card.Body className="d-flex justify-content-between px-3 py-3 align-items-start">
          <Badge bg={badgeVariant} className="badge-md-large me-3">£{price}</Badge>
          <span className="flex-grow-1">
            <h6 className="mt-0 mb-2">{offering.name} {tooltip}</h6>
            {info.map((item, index) => {
              return <Form.Text key={index} className="text-muted mb-0">{item}&nbsp;</Form.Text>
            })}
          </span>
          <AddButton offering={offering} />
        </Card.Body>
      </Card>
    )

  }


}

Purchase.propTypes = {
  quantity: PropTypes.number.isRequired
}

const mapStateToProps = (state, ownProps) => {

  let entity = getTenantEntity(state)
  if(entity){
    entity = getPaymentEntity(state, entity.id)
  }

  const cart = getTenantCart(state)

  let quantity = 0

  if(cart && cart.items){
    const found = cart.items.filter(item => {
      return item.offering.id === ownProps.offering.id
        && item.offering.resource.type === ownProps.offering.resource.type
        && item.offering.resource.id === ownProps.offering.resource.id
        && (!item.offering.gifting || item.offering.gifting.id === ownProps.offering.gifting.id)
    })
    if(found.length){
      quantity = found[0].quantity
    }
  }

  return {
    quantity
  }
}

export default connect(mapStateToProps)(Purchase)