import React from 'react'
import Badge from 'react-bootstrap/Badge'

export default (props) => {
  const { label, variant } = ({
    open: {
      label: 'Open',
      variant: 'primary'
    },
    awaiting_payment: {
      label: 'Processing payment',
      variant: 'warning'
    },
    cancelled: {
      label: 'Cancelled',
      variant: 'secondary'
    },
    paid: {
      label: 'Paid',
      variant: 'success'
    },
    dispatched: {
      label: 'Dispatched',
      variant: 'success'
    }
  }[props.status])
  return <Badge bg={variant}>{label}</Badge>
}